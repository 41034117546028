
export type StatusKey = "Open" | "In Progress" | "Hold" | "Forwarded" | "Resolved" | "Closed" | "My Work Items" | "assignedTo";

export const statusDictionary = {
  Open: "status_open",
  "In Progress": "status_inprogress",
  Hold: "status_hold",
  Forwarded: "status_forward",
  Resolved: "status_resolved",
  Closed: "status_closed",
  "My Work Items": "assignedto",
  assignedTo: "assignedto"
};
