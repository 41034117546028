import { getUser } from "./getCurrentUser";

export const checkCanEdit = (row: any): boolean => {

  const user = getUser();

  return row.status !== "Closed" &&
  row.status !== "Forwarded" &&
  row.status !== "Resolved" &&
  (row.status !== "Hold" || row.assignedTo === user?.email) &&
  (row.status !== "In Progress" || row.assignedTo === user?.email) &&
  (row.status !== "Open" || row.assignedTo === user?.email) &&
  row.assignedTo !== "" &&
  row.assignedTo !== "<empty>";

};
