import React, { useState } from "react";
import { Toolbar, alpha, Typography, Button } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { EnhancedTableToolbarProps } from "./Table.types";
import { useAuthl } from "../../../contexts/authContext";
import styles from "./EnhancedTableToolbar.module.css";
import { manuallyAssignItem } from "../../../aws/dynamoDB/emails";
import { unassignWorkFromList } from "../../../helpers/unassignWorkFromList";
import { EMAIL_ACTION_TYPE, TABLE_TYPE } from "../../../store/actions/types";
import { getUser, User } from "../../../helpers/getCurrentUser";
import { addUserToQueue } from "../../../aws/dynamoDB/activity";
import { Modal } from "../index";

const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = ({
  numSelected, tableTitle, emailDispatch, emailState, tableType, createUser
}) => {

  const { isAssigning, assignedWorkItems, setAssignedWorkItems, setAssignedSwitch, setIsAssigning } = useAuthl();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleUnassign = async () => {
    if (emailState && emailDispatch) {

      setModalOpen(false);
      const work = sessionStorage.getItem("workItems");
      const workItem = work && JSON.parse(work);
      const user: User | null = getUser();

      await manuallyAssignItem(workItem.conversationID, workItem.emailAddress, "Unassign");

      const newList = unassignWorkFromList(emailState.list, workItem);

      emailDispatch({
        type: EMAIL_ACTION_TYPE.UPDATE_EMAILS,
        payload: { list: newList }
      });
      setAssignedSwitch((value) => !value);

      setAssignedWorkItems(null);
      sessionStorage.removeItem("workItems");

      if (user?.status == "Available" && user) {
        setIsAssigning(true);
        //add the user to the queue
        await addUserToQueue(user.email, user.queue);
      }
    }
  };

  return (
    <>
      <Modal
        open={modalOpen}
        handleClose={() => { setModalOpen(false); }}
        onConfirm={handleUnassign}
        subtitle="Are you sure you want to unassign this item?"
      />
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <div className="table-title">
              {tableTitle}
              {tableType === 0 &&
                <div className={styles.title_right}>
                  {isAssigning && <p className="table-title-polling">Assigning ...</p>}
                  <Button variant="contained" onClick={() => { setModalOpen(true); }} disabled={!assignedWorkItems} >Unassign Item</Button>
                </div>
              }
            </div>
          </Typography>
        )}
        {/* {numSelected > 0 ? (
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                <div className="table-title">
                  {tableTitle}
                  {tableType == TABLE_TYPE.EMAIL && isAssigning && <p className="table-title-polling">Assigning ...</p>}
                  {tableType == TABLE_TYPE.USER && <Button data-testid="createuser" variant="contained" color="primary" onClick={createUser}>Create New User</Button>}
                </div>
              </Typography>
            )}
            {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}

        {tableType == TABLE_TYPE.USER && <Button data-testid="createuser" style={{ width: "170px" }} variant="contained" color="primary" onClick={createUser}>Create New User</Button>}
      </Toolbar>

    </>
  );
};

export default EnhancedTableToolbar;

//{isAssigning && <p className="table-title-polling">Assigning ...</p>}
