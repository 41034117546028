import React, { ReactNode } from "react";

import styles from "./Container.module.css";

interface ContainerProps {
  children: ReactNode;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
}

const Container: React.FC<ContainerProps> = ({
  children,
  title,
  className,
  style,
}) => {
  return (
    <div className={`${styles.container} ${className}`} style={style}>
      <div className={styles.label}>{title}</div>
      <div className={styles.inputContainer}>
        {children}
        <fieldset className={styles.fieldset} aria-hidden="true">
          <legend>{title}</legend>
        </fieldset>
      </div>
    </div>
  );
};

export default Container;
