import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TablePagination,
  Table,
  TableRow,
  Button,
} from "@mui/material";
import {
  renderStatus,
  renderDetailsButton,
  emptyRows,
  visibleRows,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
} from "../helpers/tableHelpers";
import { Modal, Loader } from "../index";

import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getUser, User } from "../../../helpers/getCurrentUser";
import { updateEmailStatus, checkAvailaiblity, manuallyAssignItem } from "../../../aws/dynamoDB/emails";
import { EmailFields, UserFields, Order } from "./Table.types";
import EnhancedTableHead from "./EnhancedTableHead";
import { useAuthl } from "../../../contexts/authContext";
import { EMAIL_ACTION_TYPE, TABLE_TYPE } from "../../../store/actions/types";
import { timestampToDate } from "../../../helpers/timestampToString";

interface TableProps {
  rows: any[];
  filters: string[];
  emailDispatch: (action: { type: EMAIL_ACTION_TYPE; payload: any }) => void;
  emailState: { list: any[], userLoggedIn: boolean };
}

interface Body {
  email: string | null,
  numConversations: number,
  firstName: string | undefined,
  lastName: string | undefined,
}

const EmailTable: React.FC<TableProps> = ({ rows, filters, emailDispatch, emailState }) => {

  const navigate = useNavigate();
  const user: User | null = getUser();
  const currentEmail: string | null = user ? user.email : null;
  const { setAssignNewItemSwitch, setAssignedWorkItems, assignedWorkItems } = useAuthl();

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof EmailFields | keyof UserFields>("createdAt");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [takeItemModal, setTakeItemModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [conversationID, setConversationID] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const getEmptyRows = emptyRows(page, rows.length, rowsPerPage);

  const handleEditClick = async (row: any, canEdit: boolean) => {

    //once you click the edit button, navigate to the appropriate page.
    const queryObject = sessionStorage.getItem("Query");
    let query = queryObject && JSON.parse(queryObject);
    setIsLoading(true);

    if (canEdit && row.status === "Open"){

      const work = sessionStorage.getItem("workItems");
      const workItem = work && JSON.parse(work);

      const isAvailable = await checkAvailaiblity(workItem.conversationID, workItem.emailAddress, "Edit Check");

      if (!isAvailable){
        setError("This item is taken by another user. Refresh your page.");
        setTimeout(() => {
          setError(null);
        },6000);
        setIsLoading(false);
        return;
      }

      await updateEmailStatus(row.conversationID, row.emailAddress, "In Progress", "", row.holdExpireDate, row.assignDateStart);

      const newConversations = emailState.list.map((value: any) => {
        if (value.conversationID === row.conversationID){
          return{
            ...value,
            status: "In Progress"
          };
        }
        return value;
      });

      emailDispatch({
        type: EMAIL_ACTION_TYPE.FETCH_EMAILS,
        payload: { list: newConversations },
      });
      setAssignNewItemSwitch(true);
    }

    query = { ...query, queryCurrentConversation: row };
    sessionStorage.setItem("Query", JSON.stringify(query));
    setIsLoading(false);
    navigate({
      pathname: `/email/${row.conversationID}`,
      search: createSearchParams({
        canEdit: canEdit ? "true" : "false",
        // subject: row.subject,
        // emailAddress: row.emailAddress
      }).toString()
    });
  };

  const handleTakeItem = async () => {
    console.log("take item", conversationID, emailAddress);

    setIsLoading(true);
    setTakeItemModal(false);
    const user = getUser();

    //check users status, if available they should not be able to take an item.
    if (user?.status === "Available"){
      setError("Please change your status to Busy before selecting an item.");
      setTimeout(() => {
        setError(null);
      },6000);
      setIsLoading(false);
      return;
    }

    //Check Local Storage
    const work = sessionStorage.getItem("workItems");

    if (work && work !== "assign"){
      setError("An item is already assigned to you. To select a new item, please first unassign the current one by clicking Unassign.");
      setTimeout(() => {
        setError(null);
      },10000);
      setIsLoading(false);
      return;
    }

    //Chcek that the item is not taken. because table is not automatically updated.
    const isAvailable = await checkAvailaiblity(conversationID, emailAddress, "Manual Assign Check");

    if (!isAvailable){
      setError("The item has already been taken. Please refresh your page to see the latest worklist.");
      setTimeout(() => {
        setError(null);
      },6000);
      setIsLoading(false);
      return;
    }

    //handle take item. make a reuqest and also update the emailState.
    const res = await manuallyAssignItem(conversationID, emailAddress, "Assign");

    const currentTimestamp: number = Math.floor(Date.now() / 1000);
    let newWorkItem = null;

    const newRows = rows.map((value) => {
      if (value.emailAddress === emailAddress && value.conversationID === conversationID){

        const workItem = {
          ...value,
          assignedTo: user?.email,
          assignDateStart: currentTimestamp,
          holdExpireDate: currentTimestamp + (3 * 60 * 60),
          lastUpdated: currentTimestamp * 1000,
          allocationType: "Manual"
        };

        newWorkItem = workItem;

        return workItem;
      }
      return value;
    });

    setAssignedWorkItems(newWorkItem);
    sessionStorage.setItem("workItems", JSON.stringify(newWorkItem));

    emailDispatch({
      type: EMAIL_ACTION_TYPE.UPDATE_EMAILS,
      payload: { list: newRows }
    });

    setIsLoading(false);
  };

  const handleOpenTakeItemModal = (conversationID:string, emailAddress:string) => {
    setTakeItemModal(true);
    setConversationID(conversationID);
    setEmailAddress(emailAddress);
  };

  const canBeManuallyAssigned = (row: any) => (row.status === "Open" && (row.assignedTo === "" || row.assignedTo === "<empty>" || row.assignedTo === undefined));

  return (
    <>
      {
        isLoading &&
        <Loader />
      }
      <Modal
        open={takeItemModal}
        handleClose={() => {setTakeItemModal(false);}}
        onConfirm={() => {handleTakeItem();}}
        subtitle="Are you sure you want to take this item?"
      />
      { error !== null &&
        <div style={{
          position: "fixed",
          top: "0",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "orange",
          padding: "10px 20px",
          border: "1px solid black",
          zIndex: 100,
          width: "auto",
          textAlign: "center",
          marginTop: "1em"
        }}>
          <p>{error}</p>
        </div>
      }

      <Box sx={{ width: "100%", height: "inherit" }}>
        <Paper
          sx={{
            width: "100%",
            height: "inherit",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <EnhancedTableToolbar numSelected={selected.length} tableTitle="Emails" emailDispatch={emailDispatch} emailState={emailState} createUser={undefined} tableType={TABLE_TYPE.EMAIL}/>
          <TableContainer
            sx={{ width: "-webkit-fill-available", height: "-webkit-fill-available", padding: "0px 16px" }}
          >
            <Table
              sx={{ minWidth: 750, fontSize: "inherit" }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={(e, property) =>
                  handleRequestSort(e, property, order, orderBy, setOrder, setOrderBy)}
                rowCount={rows.length}
                tableType={TABLE_TYPE.EMAIL}
              />
              <TableBody >
                {visibleRows(page, rowsPerPage, rows, order, orderBy).map((row: any, index) => {
                // const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                    // hover
                    // onClick={(event) => handleClick(event, row.id)}
                    // role="checkbox"
                    // aria-checked={isItemSelected}
                      tabIndex={-1}
                      data-testid="row"
                      key={row.conversationID + index}
                      sx={{ cursor: "default" }}
                    >
                      {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        // checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell> */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row?.conversationID}
                      </TableCell>
                      <TableCell align="left">{row.subject}</TableCell>
                      <TableCell align="center">
                        {row.emailAddress}
                      </TableCell>
                      <TableCell
                        title={row?.assignedTo}
                        align="center"
                      >
                        {row.assignedTo ? row.assignedTo : (!assignedWorkItems && canBeManuallyAssigned(row) &&
                        <Button onClick={() => handleOpenTakeItemModal(row.conversationID, row.emailAddress)} size="small" variant="outlined">Take Item</Button>)}
                      </TableCell>
                      <TableCell align="center">
                        {renderStatus(row.status)}
                      </TableCell>
                      <TableCell align="center">{timestampToDate(row?.createdAt)}</TableCell>
                      <TableCell align="center">{timestampToDate(row?.lastUpdated)}</TableCell>
                      <TableCell align="center">
                        {renderDetailsButton(row, currentEmail, handleEditClick)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {getEmptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * getEmptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 30, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => handleChangePage(e, newPage, setPage)}
            onRowsPerPageChange={(e) => handleChangeRowsPerPage(e, setRowsPerPage, setPage)}
            sx={{ minHeight: "52px" }}
          />
        </Paper>
        {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      </Box>
    </>
  );
};

export default EmailTable;
