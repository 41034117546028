import React, { useEffect, useState } from "react";
import { Badge } from "../General";
import styles from "./EmailsMenuItem.module.css";
import { StatusKey, statusDictionary } from "../../helpers/statusDict";

const EmailsMenuItem: React.FC<{
  className?: string;
  text: string;
  onClick?: (value: string) => void;
  // number?: number;
  searchParams?: URLSearchParams;
}> = ({ className = "", text, onClick, searchParams }) => {

  const initialquery = sessionStorage.getItem("Query");
  const query = initialquery && JSON.parse(initialquery);

  const [active, setActive] = useState<boolean>(query ? query.queryArray?.includes(statusDictionary[text as StatusKey]) : false);

  return (
    <div
      className={`${styles.container} ${className} ${active && styles.active}`}
      onClick={() => {
        if (onClick) onClick(text.replaceAll(" ", ""));
        setActive((prev) => !prev);
      }}
    >
      <div className={styles.textContainer}>{text}</div>
      {/* <Badge className={styles.badge} number={number} /> */}
    </div>
  );
};

export default EmailsMenuItem;
