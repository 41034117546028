import { Modal } from "@mui/material";
import UserTable from "../components/General/Table/UserTable";
import { useEffect, useState } from "react";
import ReplayIcon from "@mui/icons-material/Replay";
import styles from "./ManageAccountsPage.module.css";
import { getUsers } from "../aws/dynamoDB/users";
import { Loader } from "../components/General/index";

const ManageAccountsPage = () => {
  const session = sessionStorage.getItem("Session");
  const idToken = JSON.parse(session ?? "").idToken.jwtToken;

  // States for whether the modal should be visible.
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // State for the currently selected user (if edit modal chosen)
  const [userData, setUserData] = useState<{ email: string, firstName: string, lastName: string, accountType: string, queueAccess: string }>();

  const tableStyle = {
    width: "100%",
  };

  useEffect(() => {
    const setRowsUsers = async () => {
      const users = await getUsers();
      console.log(users);
      setRows(users.map((row: any) => {
        return {
          email: row.email,
          firstName: row.firstName,
          lastName: row.lastName,
          queueAccess: row.queue === "resolution" ? "EN Resolutions Mumbai" : "EN Complex Queries",
          accountType: !row.accountType ? "Advisor" : row.accountType,
        };
      }));
    };
    setRowsUsers();
  }, []);
  const [rows, setRows] = useState<any[]>([]);

  const getValFromQueueLabel = (queue: string): string => {
    if (queue === "EN Complex Queries") return "complex";
    if (queue === "EN Resolutions Mumbai") return "resolution";
    return queue;
  };

  // When the user clicks "save" after editing.
  const editUserHandler = async (email: string, firstName: string, lastName: string, accountType: string, queueAccess: string) => {
    // temporary impl. Will need implementation for backend.
    const response = await fetch(process.env.REACT_APP_BASE_URL + "/users/system_admin_rights", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": idToken
      },
      body: JSON.stringify({
        email: (email as string).toLowerCase(),
        firstname: firstName,
        lastname: lastName,
        accountType: accountType,
        newQueue: getValFromQueueLabel(queueAccess)
      })
    });
    const json = await response.text();
    console.log(json);

    const userIndex = rows.indexOf(rows.find((row: any) => row.email === email));
    const newRows = rows.map((row: any, index: number) => {
      if (index === userIndex) {
        return { email, firstName, lastName, accountType, queueAccess };
      } else {
        return row;
      }
    });
    setRows((rows: any) => newRows);
    setIsLoading(false);
  };

  // When the user clicks "save" after filling out a new user.
  const createUserHandler = async (email: string, firstName: string, lastName: string, accountType: string, queueAccess: string) => {
    // temporary impl. Will need implementation for backend.
    setIsLoading(true);

    const response = await fetch(process.env.REACT_APP_BASE_URL + "/users/system_admin_rights", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": idToken
      },
      body: JSON.stringify({
        email: (email as string).toLowerCase(),
        firstname: firstName,
        lastname: lastName,
        accountType: accountType,
        newQueue: getValFromQueueLabel(queueAccess)
      })
    });

    console.log(await response.json());

    if (rows.find((row: any) => row.email === email)) {
      return false;
    } else {
      setRows((rows: any) => [...rows, {
        email,
        firstName,
        lastName,
        accountType,
        queueAccess
      }]);
    }
    setIsLoading(false);

  };

  // When user clicks edit button on row in table
  const editUser = (userData: any) => {
    setUserData(userData);
    setEditModalOpen(true);
  };

  // When user clicks "create new user" button
  const createUser = () => {
    setCreateModalOpen(true);
  };

  // determines whether or not the system admin has made changes to the user profile they're editing. If so, change the "close" button to "close without saving."
  const hasBeenChanged = (userData: any, firstName: string | undefined, lastName: string | undefined, email: string | undefined, accountType: string | undefined, queueAccess: string | undefined) => {
    return !(userData?.firstName === firstName &&
      userData?.lastName === lastName &&
      userData?.email === email &&
      userData?.accountType === accountType &&
      userData?.queueAccess === queueAccess);
  };

  // UI for Create User Modal
  const CreateUserModal = () => {
    // States for each field for the user. First three are empty as they will be filled out, but the state of accountType and queueAccess are controlled by a "select", which has a default display value.
    // If the user see's the default and is okay with that, if they don't click on it, these states would stay undefined. Hence, i've set these to the default values.
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [accountType, setAccountType] = useState<string>("Advisor");
    const [queueAccess, setQueueAccess] = useState<string>("EN Resolutions Mumbai");

    const [alreadyExists, setAlreadyExists] = useState(false);

    return <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.topButtonsContainer} style={{ justifyContent: "flex-end" }}>
          <div className={styles.closeButton} onClick={() => setCreateModalOpen(false)}>
            <div style={{ margin: 10, marginTop: 7 }}>Close</div>
          </div>
        </div>
        <div className={styles.title}>
          <div style={{ fontSize: 26, fontWeight: "700" }}>
            Create New User
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div>
            First Name
          </div>
          <input type="text" value={firstName} style={{ width: "30%", padding: 10 }} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div className={styles.inputContainer}>
          <div>
            Last Name
          </div>
          <input type="text" value={lastName} style={{ width: "30%", padding: 10 }} onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div className={styles.inputContainer}>
          <div>
            Email
          </div>
          <input type="text" value={email} style={{ width: "30%", padding: 10 }} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className={styles.inputContainer}>
          <div>
            Account Type
          </div>
          <select value={accountType} style={{ width: "calc(30% + 25px)", padding: 10 }} onChange={(e) => setAccountType(e.target.value)}>
            <option value={"System Administrator"}>System Administrator</option>
            <option value={"Advisor"}>Advisor</option>
          </select>
        </div>
        <div className={styles.inputContainer}>
          <div>
            Queue Access
          </div>
          <select value={queueAccess} style={{ width: "calc(30% + 25px)", padding: 10 }} onChange={(e) => setQueueAccess(e.target.value)}>
            <option value={"EN Resolutions Mumbai"}>EN Resolutions Mumbai</option>
            <option value={"EN Complex Queries"}>EN Complex Queries</option>
          </select>
        </div>
        {alreadyExists && <div style={{ width: "100%", textAlign: "center", fontSize: 20, color: "red", fontWeight: 500 }}>
          A user with that email address already exists. Please try again.
        </div>}
        <div className={styles.saveButtonContainer}>
          <div className={styles.saveButton} onClick={async () => {
            // Save to db - call something from src/aws/dynamoDB/users.tsx
            if (!email || !firstName || !lastName) return;
            setIsLoading(true);
            setCreateModalOpen(false);

            const response = await createUserHandler(email, firstName, lastName, accountType, queueAccess); // should add rows in dynamoDB and set up cognito account with temporary password
            if (response === false) {
              setAlreadyExists(true);
              setTimeout(() => {
                setAlreadyExists(false);
              }, 5000);

            } else {
              setCreateModalOpen(false);
            }

            setIsLoading(false);
          }}>
            Create User
          </div>
        </div>
      </div>
    </div>;
  };

  // UI for Edit User Modal
  const EditUserModal = () => {
    const [firstName, setFirstName] = useState(userData?.firstName);
    const [lastName, setLastName] = useState(userData?.lastName);
    const [email, setEmail] = useState(userData?.email);
    const [accountType, setAccountType] = useState(userData?.accountType);
    const [queueAccess, setQueueAccess] = useState(userData?.queueAccess);

    // Resets state of each user data field to what's currently stored.
    const undoChanges = () => {
      setFirstName(userData?.firstName);
      setLastName(userData?.lastName);
      setEmail(userData?.email);
      setAccountType(userData?.accountType);
      setQueueAccess(userData?.queueAccess);
    };

    return <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.topButtonsContainer}>
          <div className={styles.undoChanges}>
            <div style={{ margin: 10 }} onClick={undoChanges}>
              <ReplayIcon fontSize="medium" />
            </div>
          </div>
          <div className={styles.closeButton} onClick={() => setEditModalOpen(false)}>
            <div style={{ margin: 10, marginTop: 7 }}>{hasBeenChanged(userData, firstName, lastName, email, accountType, queueAccess) ? "Close without Saving" : "Close"}</div>
          </div>
        </div>
        <div className={styles.title}>
          <div style={{ fontSize: 26, fontWeight: "700" }}>
            Edit User
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div>
            First Name
          </div>
          <input type="text" value={firstName} style={{ width: "30%", padding: 10 }} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div className={styles.inputContainer}>
          <div>
            Last Name
          </div>
          <input type="text" value={lastName} style={{ width: "30%", padding: 10 }} onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div className={styles.inputContainer}>
          <div>
            Email
          </div>
          <input disabled={true} type="text" value={email} style={{ width: "30%", padding: 10 }} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className={styles.inputContainer}>
          <div>
            Account Type
          </div>
          <select value={accountType} style={{ width: "calc(30% + 25px)", padding: 10 }} onChange={(e) => setAccountType(e.target.value)}>
            <option value={"System Administrator"}>System Administrator</option>
            <option value={"Advisor"}>Advisor</option>
          </select>
        </div>
        <div className={styles.inputContainer}>
          <div>
            Queue Access
          </div>
          <select value={queueAccess} style={{ width: "calc(30% + 25px)", padding: 10 }} onChange={(e) => setQueueAccess(e.target.value)}>
            <option value={"EN Resolutions Mumbai"}>EN Resolutions Mumbai</option>
            <option value={"EN Complex Queries"}>EN Complex Queries</option>
          </select>
        </div>
        <div className={styles.saveButtonContainer}>
          <div className={styles.saveButton} onClick={async () => {
            // Save to db - call something from src/aws/dynamoDB/users.tsx

            if (!email || !firstName || !lastName || !accountType || !queueAccess) return;
            setIsLoading(true);
            setEditModalOpen(false);

            await editUserHandler(email, firstName, lastName, accountType, queueAccess); // updates relevant rows in dynamoDB.
            setEditModalOpen(false);
            setUserData(undefined);

            setIsLoading(false);
          }}>
            Save & Exit
          </div>
        </div>
      </div>
    </div>;
  };

  // UI for page. onBackdropClick is deprecated, yet it still works. performs the "click away to close modal" functionality
  return (
    <>
      { isLoading &&
        <Loader />
      }
      <div style={tableStyle}>
        {/* setUserData(undefined) is here otherwise it's staying in memory and may show incorrect data when clicking edit */}
        <Modal onBackdropClick={() => { setEditModalOpen(false); setUserData(undefined); }} open={editModalOpen}>
          {<EditUserModal />}
        </Modal>
        <Modal onBackdropClick={() => setCreateModalOpen(false)} open={createModalOpen}>
          <CreateUserModal />
        </Modal>
        <UserTable setIsLoading={setIsLoading} createUser={createUser} editUser={editUser} rows={rows} />
      </div>
    </>
  );
};

export default ManageAccountsPage;
