import { EmailFields, Order, HeadCell, UserFields } from "../Table/Table.types";
import styles from "./tableHelpers.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { checkCanEdit } from "../../../helpers/checkCanEdit";
import React from "react";

const parseDate = (date: string): Date => {
  // const actualDate = new Date();
  // const splitByTime = date.split(" at ");
  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  // let splitDate = splitByTime[0].split(" ");
  // if (splitDate.length === 1) {
  //   splitDate = splitDate[0].split("-").reverse();
  // } else {
  //   splitDate[1] = String(months.indexOf(splitDate[1]) + 1);
  // }
  // const time = splitByTime[1].split(":");
  // actualDate.setDate(Number(splitDate[0]));
  // actualDate.setMonth(Number(splitDate[1]) - 1);
  // actualDate.setFullYear(Number(splitDate[2]));
  // actualDate.setHours(Number(time[0]));
  // actualDate.setMinutes(Number(time[1]));
  // actualDate.setSeconds(Number(time[2]));
  return new Date();
};

// function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
//   if (orderBy === "createdAt" || orderBy === "lastModified") {
//     if (parseDate(a[orderBy] as string) < parseDate(b[orderBy] as string)) {
//       return 1;
//     }
//     if (parseDate(a[orderBy] as string) > parseDate(b[orderBy] as string)) {
//       return -1;
//     }
//     return 0;
//   }
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === "createdAt" || orderBy === "lastUpdated") {
    // Directly compare as numbers
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  if (stabilizedThis.length === 0 ) return[];
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCellsUsers: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Full Name",
    sortable: true
  },
  {
    id: "email",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Email",
    sortable: true
  },
  {
    id: "accountType",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Account Type",
    sortable: true
  },
  {
    id: "queueAccess",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Queue Access",
    sortable: true
  },
  {
    id: "action",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Action",
    sortable: false
  },
];

const headCellsEmails: readonly HeadCell[] = [
  {
    id: "id",
    numeric: false,
    align: "center",
    disablePadding: true,
    label: "Reference ID",
    sortable: true
  },
  {
    id: "subject",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Subject",
    sortable: true
  },
  {
    id: "sender",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Sender",
    sortable: true
  },
  {
    id: "assignedTo",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Assigned to",
    sortable: true
  },
  {
    id: "status",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Status",
    sortable: true
  },
  {
    id: "createdAt",
    numeric: true,
    align: "center",
    disablePadding: false,
    label: "Created At",
    sortable: true
  },
  {
    id: "lastUpdated",
    numeric: true,
    align: "center",
    disablePadding: false,
    label: "Last modified",
    sortable: true
  },
  {
    id: "action",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Action",
    sortable: false
  },
];

const renderStatus = (value: string) => {
  const newValue = value.replace(" ", "");
  return <div className={styles[newValue]}>{value}</div>;
};

const emptyRows = (page: number, rowLength: number, rowsPerPage: number): number =>
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowLength) : 0;

const visibleRows = (page: number, rowsPerPage: number, rows: any[], order: Order, orderBy: keyof EmailFields | keyof UserFields) => {
  return React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );
};

const handleRequestSort = (
  event: React.MouseEvent<unknown>,
  property: keyof EmailFields | keyof UserFields,
  order: Order,
  orderBy: keyof EmailFields | keyof UserFields,
  setOrder: React.Dispatch<React.SetStateAction<Order>>,
  setOrderBy: React.Dispatch<React.SetStateAction<keyof EmailFields | keyof UserFields>>
) => {
  const isAsc = orderBy === property && order === "asc";
  setOrder(isAsc ? "desc" : "asc");
  setOrderBy(property);
};

const handleChangePage = (
  event: unknown,
  newPage: number,
  setPage: React.Dispatch<React.SetStateAction<number>>
) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>,
  setPage: React.Dispatch<React.SetStateAction<number>>
) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

const renderDetailsButton = (row: any, user: string | null, onClick?: (row: any, canEdit: boolean) => void) => {

  const canEdit: boolean = checkCanEdit(row);

  return (
    <strong>
      {canEdit
        ?
        <div className={styles.buttonContainer}>
          <VisibilityIcon
            className={styles.editVisibleButton}
            onClick={() => (onClick ? onClick(row, false) : "")}
          >
          </VisibilityIcon>
          <EditIcon
            className={styles.editEditButton}
            onClick={() => (onClick ? onClick(row, true) : "")}
          >
          </EditIcon>
        </div>
        :
        <VisibilityIcon
          className={styles.seeVisibleButton}
          onClick={() => (onClick ? onClick(row, false) : "")}
          style={{ display: "block", margin: "0 auto" }}
        ></VisibilityIcon>
      }
    </strong>
  );
};

export {
  getComparator,
  stableSort,
  headCellsEmails,
  headCellsUsers,
  renderStatus,
  renderDetailsButton,
  emptyRows,
  visibleRows,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage
};
