import { handleLogout } from "../../helpers/logout";
import { isTokenExpired } from "../../helpers/tokenRefresh";

/**
 * Get the users details
 * @param email Document Id that you want to retrieve
 * @returns The users details
 */
export const fetchUserDetails = async (email: string, idToken: string) => {

  await isTokenExpired();

  const initialresponse = await fetch(`${process.env.REACT_APP_BASE_URL}/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": idToken
    },
    body: JSON.stringify({ email })
  });

  if (!initialresponse.ok && initialresponse.status === 401) {
    const userInfo = JSON.parse(sessionStorage.getItem("User") ??"");
    await handleLogout(userInfo);
    return null;
  }
  // const response = await Axios.get(queryString);
  const response = await (initialresponse.json());

  return response.User;
};

/**
 * Update the status of an email thread to newStatus
 * @param email
 * @param status: "Available" || "Busy" || "Offline"
 * @param queue
 * @param emailAddress
 * @param conversationId
 * @param messageId
 */
export const updateActivityStatus = async (
  email: string,
  status: string,
  queue: string,
  emailAddress: string,
  conversationId: string,
  conversationRecieptHandle: string,
  holdExpire: string
): Promise<void | null> => {

  await isTokenExpired();

  try{
    const statusBody = {
      email,
      status,
      queue,
      assignedEmailAddress: emailAddress,
      assignedConversationId: conversationId,
      assignedRecieptHandle: conversationRecieptHandle,
      holdExpire
    };

    const session = sessionStorage.getItem("Session");
    const idToken = JSON.parse(session ?? "").idToken.jwtToken;

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": idToken
      },
      body: JSON.stringify(statusBody)
    });

    if (!response.ok && response.status === 401) {
      const userInfo = JSON.parse(sessionStorage.getItem("User") ??"");

      await handleLogout(userInfo);
      return null;
    }

  } catch (error){
    console.log(error);
  }
};

/**
 * Adds user to a queue
 * @param email
 * @param queue
 */
export const addUserToQueue = async (email: string, queue: string) => {

  await isTokenExpired();

  const work = sessionStorage.getItem("workItems");
  const workItems: any = work && JSON.parse(work);

  const session = sessionStorage.getItem("Session");
  const idToken = JSON.parse(session ?? "").idToken.jwtToken;
  if (!workItems || workItems === "assign") {
    try {
      const bodyParams = {
        email,
        queue
      };

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/emails/assign/addToQueue`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": idToken
        },
        body: JSON.stringify(bodyParams)
      });

      if (!response.ok && response.status === 401) {
        const userInfo = JSON.parse(sessionStorage.getItem("User") ??"");

        await handleLogout(userInfo);
        return null;
      }

    } catch (error) {
      console.log(error);
    }
  }
};
