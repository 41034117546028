import { handleLogout } from "../../helpers/logout";
import { isTokenExpired } from "../../helpers/tokenRefresh";

export const getUsers = async () => {
  console.log("getUsers()");
  await isTokenExpired();
  try {
    const bodyParams = {
      email: "ALL"
    };

    const session = sessionStorage.getItem("Session");
    const idToken = JSON.parse(session ?? "").idToken.jwtToken;
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": idToken
      },
      body: JSON.stringify(bodyParams)
    });

    console.log("RESPONSE: ", response);

    if (!response.ok && response.status === 401) {
      const userInfo = JSON.parse(sessionStorage.getItem("User") ??"");

      await handleLogout(userInfo);
      return null;
    }
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

export const deleteUser = async (row: any) => {
  try {

    const session = sessionStorage.getItem("Session");
    const idToken = JSON.parse(session ?? "").idToken.jwtToken;

    const response = await fetch(process.env.REACT_APP_BASE_URL + "/users/system_admin_rights", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": idToken
      },
      body: JSON.stringify({
        email: row.email.toLowerCase()
      })
    });

  } catch (error) {
    console.log(error);
    return "Internal Server Error";
  }
};
