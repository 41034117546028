import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LogoutNavigate = () => {

  const navigate = useNavigate();

  useEffect(() => {
    const handleTokenRefreshFailed = () => {
      console.log("RAN HERE");
      sessionStorage.clear();
      localStorage.clear();

      navigate("/login");
    };

    window.addEventListener("log-user-out", handleTokenRefreshFailed);

    return () => {
      window.removeEventListener("log-user-out", handleTokenRefreshFailed);
    };
  }, [navigate]);

  return null;
};

export default LogoutNavigate;
