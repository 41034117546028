export enum ACTION_LIST_TYPE{
  ADD_TO_ACTION_LIST = "ACTIONS/ADD_ACTION",
  DELETE_ACTION = "ACTIONS/DELETE_ACTION",
  MODIFY_ACTION = "ACTIONS/MODIFY_ACTION",
  SELECT_ACTION = "ACTIONS/SELECT_ACTION",
}

export enum TABLE_TYPE {
  EMAIL,
  USER
}

export enum EMAIL_ACTION_TYPE {
  FETCH_EMAILS = "EMAILS/FETCH",
  LOGIN = "EMAILS/LOGIN",
  UPDATE_EMAILS = "EMAILS/ADD"
}

interface ADD_TO_ACTION_LIST {
  type: ACTION_LIST_TYPE.ADD_TO_ACTION_LIST
  payload: string
}

interface DELETE_ACTION {
  type: ACTION_LIST_TYPE.DELETE_ACTION
  payload: string
}

export type AuthState = {
  isLoggedIn: boolean;
};

export type Action =
  | { type: "LOGIN" }
  | { type: "LOGOUT" };

export type actionListActions = ADD_TO_ACTION_LIST | DELETE_ACTION
