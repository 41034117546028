// export const kanaMap = {
//   "Eresolve Reassign": "eresolveai@easyjet.com",
//   // "PT Complex Queries": "apoio.cliente@easyJet.com",
//   "CPT - EN Executive Support": "esupport@easyjet.com",
//   // "DE Complex Queries": "kunden.service@easyJet.com",
//   "DE Group Bookings": "groupde@easyjet.com",
//   "DE Regulatory": "claimsde@easyjet.com",
//   "DE plus card queries": "Service.easyJetplusDE@easyjet.com",
//   "DE Resolutions": "spezialteam@easyjet.com",
//   "EN B2B": "b2b@easyjet.com",
//   "EN Complex Queries": "customer.service@easyjet.com",
//   "EN easyJet Plus queries": "Service.easyJetplusEN@easyjet.com",
//   "EN Group Bookings": "groups@easyjet.com",
//   "EN Regulatory": "claims@easyjet.com",
//   "EN Privacy": "privacy@easyJet.com",
//   "EN Serious illness": "si.support@easyjet.com",
//   "EN Special Assistance": "special.assistance@easyjet.com",
//   // "ES Complex Queries": "servicio.cliente@easyJet.com",
//   "ES Group Bookings": "groupes@easyjet.com",
//   "ES Special Assistance": "assistance.speciale@easyjet.com",
//   "ES Regulatory": "claimses@easyjet.com",
//   "ES Resolutions": "equipo.expeditivo@easyjet.com",
//   "FR B2B": "b2bfr@easyjet.com",
//   // "FR Complex Queries": "service.client@easyjet.com",
//   "FR easyJet Plus queries": "Service.easyJetplusFR@easyjet.com",
//   "FR Group Bookings": "groupfr@easyjet.com",
//   "FR Regulatory": "claimsfr@easyjet.com",
//   "FR Resolutions": "equipe.specialistes@easyjet.com",
//   "NL Regulatory": "claimsnl@easyjet.com",
//   // "IT Complex Queries": "servizio.clienti@easyjet.com",
//   "IT Group Bookings": "groupit@easyjet.com",
//   "IT Special Assistance": "assistenza.speciale@easyjet.com",
//   "IT Regulatory": "claimsit@easyjet.com",
//   "IT Resolutions": "team.specialisti@easyjet.com",
//   // "NL Complex Queries": "klanten.service@easyjet.com",
//   "PT Group Bookings": "grouppt@easyjet.com",
//   "PT Regulatory": "claimspt@easyjet.com",
//   "PT Resolutions": "equipa.especialista@easyjet.com",
// };

// Testing
export const kanaMap = {
  "Default Test": "verint_test_email@easyjet.com",
  "Alba": "a.yechen@reply.com",
  "Nathan": "n.sharples@reply.com",
  "Tester 4": "tester4@20240415.easj-191.nccpentest.com"
};
