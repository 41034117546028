import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "./EmailThreadAccordion.module.css";

interface EmailThreadAccordionProps {
  emails: any[];
  setEmailIndex: React.Dispatch<React.SetStateAction<number>>;
}
const EmailThreadAccordion: React.FC<EmailThreadAccordionProps> = ({
  emails = [],
  setEmailIndex
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  useEffect(() => {
    setExpanded(`panel${emails.length - 1}`);
    setEmailIndex(emails.length-1);
  },[emails]);

  const handleChange =
    (panel: string, thisIndex: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      if (isExpanded) {
        for (let i = 0; i < emails.length; i++) {
          emails[i]["selected"] = false;
        }
        emails[thisIndex]["selected"] = true;
        setEmailIndex(thisIndex);
      } else {
        for (let i = 0; i < emails.length; i++) {
          emails[i]["selected"] = false;
        }
        setEmailIndex(-1);
      }
    };

  return (
    <div className={styles.container}>
      {emails.map((email, index) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`, index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <div className={styles.summaryContainer}>
                <Typography variant="body2">
                  {new Date(Number(email?.date))
                    .toLocaleString("en-GB", { timeZone: "UTC" })}
                </Typography>
                <Typography>{email.customerResponse ? `Customer: ${email?.emailAddress}` : email.emailAddress ? `Advisor: ${email.emailAddress}` : "Advisor: eResolve"}</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              { email.customerResponse
                ?
                <Typography variant="htmlText">
                  {email?.body?.replaceAll("<br>", "\n")}
                </Typography>
                :
                <>
                  { email.response !== null &&
                  <>
                    <strong><h1 className={styles.threadTitle}>Original Generated Output:</h1></strong>
                    <h2 className={styles.threadSubject}>Subject: {email?.subject}</h2>
                    <Typography variant="htmlText">
                      {email?.response?.replaceAll("<br>", "\n")}
                    </Typography>
                    <div className={styles.threadSeperator}></div>
                  </>
                  }
                  <strong><h1 className={styles.threadTitle}>Outbound Email:</h1></strong>
                  <h2 className={styles.threadSubject}>Subject: {email?.subject}</h2>
                  <Typography variant="htmlText">
                    {email?.sent?.replaceAll("<br>", "\n")}
                  </Typography>
                </>
              }
            </AccordionDetails>
          </Accordion>
        );

        return <div key={index}></div>;
      })}
    </div>
  );
};

export default EmailThreadAccordion;
