
import React from "react";
import styles from "./knowledgeContainer.module.css";

interface Props {
  knowledgeArticles: string[];
  selected: number;
}

const KnowledgeArticleContainer: React.FC<Props> = ({ knowledgeArticles, selected }) => {
  let displayedAllArticles: any[] = [];
  if (knowledgeArticles) {
    displayedAllArticles = knowledgeArticles?.map((value: string, index: number) => {
      return <span className={styles.articleText} key={index}>{value}</span>;
    });
  } else {
    displayedAllArticles = [];
  }

  return (
    <>
      {knowledgeArticles && knowledgeArticles.length > 0 ? (
        <div className={styles.container}>
          {displayedAllArticles}
        </div>
      ) : (
        <div className={selected === -1 ? styles.smallContainer :styles.container}>
          {
            selected === -1
              ?<p>Please select an email to see articles.</p>
              :<p>There are no knowledge articles for the selected email.</p>
          }
        </div>
      )}
    </>
  );
};

export default KnowledgeArticleContainer;
