import { useState, useEffect, SetStateAction } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, Switch, TextField } from "@mui/material";
import { Container, Modal, SplitButton, Loader, KanaModal } from "../components/General";

import {
  EmailThreadAccordion,
  SideMenu,
} from "../components/MessagesPage";
import styles from "./MessagesPage.module.css";
import { fetchEmail, updateEmailStatus, sendEmail } from "../aws/dynamoDB/emails";
import {
  regenerateResponse,
  RegeneratePromptType,
} from "../aws/queries";
import { userHTMLSignature, userSignature, getUser } from "../helpers/getCurrentUser";
import { useAuthl } from "../contexts/authContext";
import { EMAIL_ACTION_TYPE } from "../store/actions/types";
import { addUserToQueue } from "../aws/dynamoDB/activity";
import { reassignToKana } from "../aws/requests";
import { buildCurrentQuery } from "../helpers/buildCurrentQuery";
import { kanaMap } from "../helpers/kanaEmails";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { styled } from "@mui/material/styles";
import { UserEmailType, FileType } from "../models/emailData.types";
import { Fullscreen } from "@mui/icons-material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MessagesPage = ({
  emailState,
  emailDispatch,
}: {
  emailState: { list: any[]; userLoggedIn: boolean };
  emailDispatch: React.Dispatch<{ type: EMAIL_ACTION_TYPE; payload: any }>;
}) => {

  const { id } = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const { setAssignNewItemSwitch, setAssignedSwitch, setAssignedWorkItems, setIsAssigning } = useAuthl();

  const [retrieveEmailError, setRetrieveEmailError] = useState("");
  const [selectedEmail, setSelectedEmail] = useState<any>({});
  const [emailIndex, setEmailIndex] = useState(-1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);
  const [openKanaModal, setOpenKanaModal] = useState<boolean>(false);
  const [openHoldModal, setOpenHoldModal] = useState<boolean>(false);
  const [openSendModal, setOpenSendModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingResponse, setLoadingResponse] = useState<boolean>(false);
  const [lastUserEmail, setLastUserEmail] = useState<UserEmailType>({});
  const [seeAllResponses, setSeeAllResponses] = useState<boolean>(false);
  const [generatedResponse, setGeneratedResponse] = useState<string[]>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sideMenuOpen, setSideMenuOpen] = useState(true);
  const [canEdit, setCanEdit] = useState(query.get("canEdit") === "true");
  const [gptResponse, setGPTResponse] = useState("");
  const [escalateToEmail, setEscalateToEmail] = useState(kanaMap[Object.keys(kanaMap)[0] as keyof typeof kanaMap]);
  const [newRecipientAddress, setNewRecipientAddress] = useState("");
  const [validEmailAddressInput, setValidEmailAddressInput] = useState(true);
  const [openSendNewRecipientModal, setOpenSendNewRecipientModal] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [attachments, setAttachments] = useState<FileType[]>([]);
  const [canEditResponse, setCanEditResponse] = useState<boolean>(false);
  const [fullSizeEditor, setFullSizeEditor] = useState<boolean>(true);
  const [rawEditorText, setRawEditorText] = useState("");

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
    const parsedConversation = sessionStorage.getItem("Query");
    const conversation = parsedConversation && JSON.parse(parsedConversation).queryCurrentConversation;

    if (conversation) {
      fetchEmail(conversation.conversationID, conversation.emailAddress)
        .then(async (res: any) => {
          if (res) {
            const user = getUser();
            if (res.assignedTo !== user?.email && res.status === "In Progress") {
              setCanEdit(false);
            } else {
              // await updateEmailStatus((id as string), "In Progress");
            }
          }
        })
        .catch(() => setRetrieveEmailError("Unable to retrieve emails"));
      const qry = sessionStorage.getItem("Query");
      const query = qry && JSON.parse(qry);

      const conversations = query?.queryCurrentConversation;
      setSelectedEmail(conversations);
    }
    return () => {
      if (conversation) {
        fetchEmail(conversation.conversationID, conversation.emailAddress).then(async (res: any) => {
          // console.log("res: " + JSON.stringify(res));
          if (res) {
            const user = getUser();
            if (res.assignedTo === user?.email) {
              // updateEmailStatus((id as string), "Open");
            }
          }
        });
      }
      setSelectedEmail({ emails:[] });
    };
  }, []);

  useEffect(() => {
    const parsedConversation = sessionStorage.getItem("Query");
    const conversation = parsedConversation && JSON.parse(parsedConversation).queryCurrentConversation;

    if (conversation) {
      fetchEmail(conversation.conversationID, conversation.emailAddress).then((res: any) => {
        if (res) {
          setLastUserEmail(
            res?.emails
              .filter(
                (email: any) => email.sender !== "EasyJet Customer Support"
              )
              .pop()
          );
          setSelectedEmail((prev: any) => ({ ...prev, emails: res.emails }));
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (lastUserEmail) {
      if (lastUserEmail.response) {
        if (lastUserEmail.date) {
          const date = new Date(Number(lastUserEmail.date)).toLocaleString("en-GB", { timeZone: "UTC" });
          // setGeneratedResponse([lastUserEmail.response.replaceAll("<br>", "\n")]);
          // setGeneratedResponse([date + "\n" + lastUserEmail.response.replaceAll("<br>", "\n")]);
          setGPTResponse(lastUserEmail.response.replaceAll("<br>", "\n"));
        }
      }
    }
  }, [lastUserEmail]);

  useEffect(() => {
    if (seeAllResponses) {
      const responses = [];
      for (const email of selectedEmail.emails) {
        if (email.response) {
          responses.push(new Date(Number(email.date)).toLocaleString("en-GB", { timeZone: "UTC" }) + "\n" + email.suggestedResponse.replaceAll("<br>", "\n"));
        }
      }
      setGeneratedResponse(responses);
    } else {
      if (generatedResponse) {
        if (lastUserEmail) {
          if (lastUserEmail.response) {
            setGeneratedResponse([generatedResponse.pop() || lastUserEmail.response.replaceAll("<br>", "\n")]);
          }
        }
      }
    }
  }, [seeAllResponses]);

  const FormattedResponse = ({ generatedResponse }: { generatedResponse: string[] }) => {
    if (seeAllResponses) {
      return <>{generatedResponse.map((genResponse, index) => {
        return <div key={index} >
          {/* <b>{genResponse.substring(0, genResponse.indexOf(("\n")))}</b>
          <br /> */}
          {genResponse.substring(genResponse.indexOf(("\n")) + 1, genResponse.length)}
          <br />
          <br />
        </div>;
      })}</>;
    } else {
      if (generatedResponse[0]) {
        return (<>
          {generatedResponse[0]}
          {/* <b>{generatedResponse[0].substring(0, generatedResponse[0].indexOf(("\n")))}</b>
          <br />
          {generatedResponse[0].substring(generatedResponse[0].indexOf(("\n")) + 1, generatedResponse[0].length)}
          <br />
          <br /> */}
        </>);
      } else {
        return <></>;
      }
    }
  };

  const navigateToTablePage = () => {
    const query = buildCurrentQuery();

    navigate(`/${query}`);
  };

  const onSend = async (overrideEmail = false) => {

    setOpenSendModal(false);
    //if item has been unassigned
    if (getWorkItem() === false && selectedEmail.status !== "Hold"){
      setOpenWarningModal(true);
      return;
    }

    const bodyHtml = gptResponse.replaceAll("\n", "<br>");
    // let bodyHtml = generateRef.current?.innerHTML.replaceAll("\n", "<br>");
    // bodyHtml = bodyHtml?.replaceAll("<br><br>", "<br>");
    let toSend = bodyHtml;
    const regex = /<([^>]+)>/;
    const match = regex.exec(lastUserEmail?.emailAddress ?? "");
    const email = match ? match[1] : lastUserEmail?.emailAddress;
    const emailToSend = overrideEmail ? newRecipientAddress : email;
    if (bodyHtml && emailToSend && selectedEmail.conversationID) {
      // if (generatedResponse[0]) {
      //   if (seeAllResponses) {
      //     // eslint-disable-next-line react/prop-types
      //     toSend = generatedResponse[generatedResponse.length - 1].replaceAll("\n", "<br>");
      //   } else {
      //     toSend = bodyHtml;
      //   }
      // } else {
      //   toSend = bodyHtml;
      // }

      // // Append the signature
      toSend += userHTMLSignature();
      setLoading(true);

      await sendEmail(
        emailToSend,
        selectedEmail.conversationID,
        toSend,
        selectedEmail.emails[selectedEmail.emails.length - 1].subject,
        selectedEmail.emails[selectedEmail.emails.length - 1].messageID,
        selectedEmail.conversationRecieptHandle,
        attachments
      );

      await updateEmailStatus(selectedEmail.conversationID, selectedEmail.emailAddress, "Resolved", selectedEmail.conversationRecieptHandle, selectedEmail.holdExpireDate, selectedEmail.assignDateStart);

      await handleListUpdate(selectedEmail.emailAddress, selectedEmail.conversationID, "Resolved");

      setLoading(false);
      navigateToTablePage();
    }
  };

  const onCloseCase = async (): Promise<void> => {

    setOpenModal(false);
    if (getWorkItem() === false && selectedEmail.status !== "Hold"){
      setOpenWarningModal(true);
      return;
    }

    if (selectedEmail.conversationID) {
      setLoading(true);

      await updateEmailStatus(selectedEmail.conversationID, selectedEmail.emailAddress, "Closed", selectedEmail.conversationRecieptHandle, selectedEmail.holdExpireDate, selectedEmail.assignDateStart);

      const qry = sessionStorage.getItem("Query");
      const query = qry && JSON.parse(qry);

      const emailAddress = query.queryCurrentConversation.emailAddress;
      const conversationID = query.queryCurrentConversation.conversationID;

      await handleListUpdate(emailAddress, conversationID, "Closed");

      setLoading(false);
      navigateToTablePage();
    }
  };

  const toggleModal = () => setOpenModal((prev) => !prev);
  const toggleKanaModal = () => setOpenKanaModal((prev) => !prev);
  const toggleHoldModal = () => setOpenHoldModal((prev) => !prev);
  const toggleSendModal = () => setOpenSendModal((prev) => !prev);
  const toggleSendNewRecipientModal = () => setOpenSendNewRecipientModal((prev) => !prev);

  const onSendNewRecipient = (address: string) => {
    //if not valid, do not send
    if (!checkValidEmailInput(address)) {
      return;
    }
    toggleSendNewRecipientModal();
    onSend(true);
  };

  const checkValidEmailInput = (address: string): boolean => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/; //regex string
    if (!re.test(String(address).toLowerCase())) {
      setValidEmailAddressInput(false);
      return false;
    }
    setValidEmailAddressInput(true);
    return true;
  };

  const editSaveHandler = () => {
    setCanEditResponse((prev) => !prev);
  };

  const editorSizeHandler = () => {
    setFullSizeEditor((prev) => !prev);
  };

  const onRegenerate = async (
    type?: RegeneratePromptType
  ) => {
    console.log(rawEditorText);
    if (selectedEmail.conversationID) {

      setLoadingResponse(true);
      await regenerateResponse({
        emailId: selectedEmail.conversationID,
        emailBody: rawEditorText ?? null,
        setGeneratedResponse: setGPTResponse,
        setLoadingResponse
      });
      // if (response) {
      //   if (seeAllResponses) {
      //     const toAdd = { suggestedResponse: response, date: new Date().toLocaleString(), summary: lastUserEmail.summary, suggestedRemediation: lastUserEmail.suggestedRemediation };
      //     selectedEmail.emails.push(toAdd);
      //     setLastUserEmail(toAdd);
      //     setGeneratedResponse([...generatedResponse, response.replaceAll("<br>", "\n")]);
      //   } else {
      //     setGeneratedResponse([response?.replaceAll("<br>", "\n")]);
      //   }
      // }

      // setLoadingResponse(false);
    }
  };

  const handleKanaRedirection = async () => {

    setOpenKanaModal(false);
    setLoading(true);
    if (getWorkItem() === false && selectedEmail.status !== "Hold"){
      setOpenWarningModal(true);
      return;
    }

    if (id) {
      await reassignToKana(id, escalateToEmail);
      await updateEmailStatus(selectedEmail.conversationID, selectedEmail.emailAddress, "Forwarded", selectedEmail.conversationRecieptHandle, selectedEmail.holdExpireDate, selectedEmail.assignDateStart);

      await handleListUpdate(selectedEmail.emailAddress, selectedEmail.conversationID, "Forwarded");
      setLoading(false);

      navigateToTablePage();
    }
  };

  const handleHoldItem = async () => {

    setOpenHoldModal(false);

    if (getWorkItem() === false && selectedEmail.status !== "Hold"){
      setOpenWarningModal(true);
      return;
    }

    const qry = sessionStorage.getItem("Query");
    const query = qry && JSON.parse(qry);

    const emailAddress = query.queryCurrentConversation.emailAddress;
    const conversationID = query.queryCurrentConversation.conversationID;
    const { conversationRecieptHandle, holdExpireDate, assignDateStart } = query.queryCurrentConversation;

    setLoading(true);
    // make a request to the hold item api endpoint
    await updateEmailStatus(conversationID, emailAddress, "Hold", conversationRecieptHandle, holdExpireDate, assignDateStart);

    // Remove Item from sessionStorage and edit the rows
    await handleListUpdate(emailAddress, conversationID, "Hold");

    setLoading(false);
    //redirect user to the main page.
    navigateToTablePage();
  };

  const getWorkItem = () => {
    const initialWork = sessionStorage.getItem("workItems");
    return initialWork ? JSON.parse(initialWork) : false;
  };

  useEffect(() => {
    const uploadFiles = async () => {
      const tmp: FileType[] = [];
      for (const file of files) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        // Wrap FileReader events in a Promise for better handling
        const onLoadPromise: () => Promise<string> = () =>
          new Promise((resolve, reject) => {
            // reader.readAsDataURL(file);
            reader.onload = () => {
              const base64String = reader.result as string;
              console.log({ base64String });
              resolve(base64String);
            };
            reader.onerror = reader.onabort = reject;
          });

        try {
          // Wait for the file to be read completely
          const thisFileData: string = await onLoadPromise();
          const fileData = {
            filename: file.name,
            path: thisFileData,
          };
          tmp.push(fileData);

          console.log("Uploaded:", file.name);
          console.log("File data:", thisFileData);

          // You can handle the API response here
          // console.log('API Response:', response);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }

      try {
        // console.log(thisFormData);
        setAttachments((formData) =>[...formData, ...tmp]);
      } catch (e) {
        console.log(e);
      }
    };

    if (files.length > 0) {
      uploadFiles();
    }
  }, [files]);

  const handleListUpdate = async (emailAddress: string, conversationID: string, newStatus: string) => {

    if (selectedEmail.status === "Open" || selectedEmail.status === "In Progress"){
      setAssignedWorkItems(null);
      sessionStorage.removeItem("workItems");

      const user = getUser();
      if (user?.status === "Available"){
        sessionStorage.setItem("workItems", JSON.stringify("assign"));
      }
    }

    const newConversations = emailState.list.filter((value: any) => {
      if (value.conversationID === conversationID && value.emailAddress === emailAddress){

        return{
          ...value,
          status: newStatus
        };
      }
      return value;
    });

    emailDispatch({
      type: EMAIL_ACTION_TYPE.FETCH_EMAILS,
      payload: { list: newConversations },
    });

    if (selectedEmail.status === "Open" || selectedEmail.status === "In Progress"){
      setAssignNewItemSwitch((value) => !value);
    }

    const user = getUser();
    if (user && user.status === "Available"){
      setIsAssigning(true);
      await addUserToQueue(user.email, user.queue);
    }

    setAssignedSwitch((value) => !value);
  };

  const toggleWarningModal = () => {
    setOpenWarningModal(false);
  };

  console.log(lastUserEmail, "summary");

  return (
    <div className={styles["main-menu"]}>
      <Modal
        open={openWarningModal}
        handleClose={toggleWarningModal}
        onConfirm={toggleWarningModal}
        subtitle="This action cannot be performed. This Item has Been Unassigned (possible cause: going offline while working on item). please return to the main page"
      />

      <Modal
        open={openModal}
        handleClose={toggleModal}
        onConfirm={onCloseCase}
        subtitle="Are you sure you want to close this case?"
      />
      <KanaModal
        open={openKanaModal}
        handleClose={toggleKanaModal}
        onConfirm={handleKanaRedirection}
        escalateToEmail={escalateToEmail}
        setEscalateToEmail={setEscalateToEmail}
        subtitle="Are you sure you want to send this case to KANA?"
      />
      <Modal
        open={openHoldModal}
        handleClose={toggleHoldModal}
        onConfirm={handleHoldItem}
        subtitle="Are you sure you want to hold onto this item for 11 hours?"
      />

      <Modal
        open={openSendModal}
        handleClose={toggleSendModal}
        onConfirm={() => onSend()}
        subtitle="Are you sure you want to send this reply to customer?"
      />

      <Modal
        open={openSendNewRecipientModal}
        handleClose={() => {
          toggleSendNewRecipientModal();
          setNewRecipientAddress("");
          setValidEmailAddressInput(true);
        }}
        onConfirm={() => onSendNewRecipient(newRecipientAddress)}
        btnText="Send"
        subtitle="Send to a Different Recipient:">
        <TextField
          error={!validEmailAddressInput}
          variant="standard"
          label={"To: (enter email address)"}
          helperText={validEmailAddressInput ? "" : "Invalid email input"}
          fullWidth
          className={styles.searchBox}
          value={newRecipientAddress}
          onChange={(e) => setNewRecipientAddress(e.target.value)}
        />
      </Modal>

      {loading && <Loader />}
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div>
            Reference ID: {id}
            <br />
            Subject: {selectedEmail.subject}
          </div>
          <div className={styles.titleButtons}>
            {canEdit &&
              <>
                <Button
                  variant="contained"
                  color="primary"
                  // className={styles.titleButton}
                  onClick={toggleHoldModal}
                >
                  Hold
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.titleKanaButton}
                  onClick={toggleKanaModal}
                  style={{ margin: 16 }}
                >
                  Reassign To Kana
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  // className={styles.titleButton}
                  onClick={toggleModal}
                >
                  Close item
                </Button>
              </>
            }
          </div>
        </div>
        <div className={styles.gridContainer}>
          <Container title="Thread History">
            { retrieveEmailError
              ? <p className={styles.errorEmailsMessage}>{retrieveEmailError}</p>
              : <EmailThreadAccordion emails={selectedEmail.emails} setEmailIndex={setEmailIndex}/>
            }
          </Container>
          <div className={seeAllResponses ? styles.secondColumnSingle : styles.secondColumnDouble}>
            {!seeAllResponses &&
              <Container title={"GPT Summary"}>
                <div className={styles.text}>{selectedEmail?.summary}</div>
              </Container>
            }
            <Container title={"Generated Output (Editing: " + (canEditResponse ? "ON" : "OFF") + ")"}>
              <div
                className={seeAllResponses ? styles.generatedOutput : styles.generatedOutputContainer}
              >
                <div
                  style={{ padding: fullSizeEditor ? "none" : "0 150px" }}
                  key={fullSizeEditor ? "fullSizeEditorOn" : "fullSizeEditorOff"}
                >
                  <ReactQuill
                    theme="snow"
                    value={gptResponse}
                    onChange={(content: string, delta: any, source: any, editor: { getText: () => SetStateAction<string>; }) => {
                      setGPTResponse(content);
                      setRawEditorText(editor.getText());
                    }}
                    readOnly={!canEditResponse}
                  />
                </div>
                <div contentEditable={false}>
                  {userSignature()}
                  <br />
                </div>
                <br />
                <br />
              </div>
              <div
                style={windowWidth <= 1375 ? { overflowX: "scroll", flexDirection: "column" } : { overflow: "hidden", flexDirection: "column" }}
                className={`${styles.buttonsContainer} ${styles.multipleChild}`}
              >
                {files.length > 0 && <div style={{ display: "flex", flexDirection: "row", fontSize: 12, overflow: "hidden" }}>
                  {Array.from(files).map((file: File, index: number) => {
                    return <div style={{ borderWidth: 1, padding: (files.length > 0 ? 10 : 0), paddingTop: 0, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} key={index}>
                      {file.name}
                    </div>;
                  })}
                </div>}
                {canEdit && <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: files.length === 0 ? "100%": "50%" }}>
                  <Button variant="outlined" size="small" disabled={loadingResponse} onClick={() => onRegenerate()}>
                    {loadingResponse && (
                      <span className={styles["button--loading"]}></span>
                    )}
                    <span
                      style={{
                        color: loadingResponse ? "transparent" : "inherit",
                      }}
                    >
                        Regenerate
                    </span>
                  </Button>
                  {/* <Button variant="outlined" size="small" onClick={() => setSeeAllResponses(prev => !prev)}>
                    {seeAllResponses ? "Hide All" : (windowWidth > 1405 ? "See All Responses" : "See All")}
                  </Button> */}
                  <div className={styles.leftButtonsContainer}>
                    <Button variant="outlined" size="small" disabled={loadingResponse} onClick={editorSizeHandler}>
                      <Fullscreen/>
                      <Switch
                        checked={fullSizeEditor}
                        color="primary"
                      />
                    </Button>
                    <Button variant="outlined" size="small" disabled={loadingResponse} onClick={editSaveHandler}>
                      Edit
                      <Switch
                        checked={canEditResponse}
                        color="primary"
                      />
                    </Button>
                    <Button component="label" data-testid="fileInputButton" variant="outlined" size="small" disabled={loadingResponse}>
                      <AttachFileIcon fontSize={"small"} /> Upload
                      <VisuallyHiddenInput data-testid="fileInput" type="file" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const file = e.target.files?.[0];
                        if (!file) return;
                        setFiles((files) => [...files, file]);}
                      } />
                    </Button>
                    {/* <Button data-testid="sendbutton" variant="outlined" size="small" disabled={loadingResponse} onClick={toggleSendModal}>
                      Send
                    </Button> */}
                    <SplitButton options={["Send to a Different Recipient"]} fixedText="Send" clickHandler={toggleSendModal} clickMenuItemHandler={toggleSendNewRecipientModal} disabled={loadingResponse}></SplitButton>
                  </div>
                </div>}
              </div>
            </Container>
          </div>
        </div>
      </div>
      <div style={sideMenuOpen ? {} : { width: 35 }} className={styles.collapseButtonContainer}>
        <button style={sideMenuOpen ? {} : { width: 35 }} onClick={() => setSideMenuOpen(prev => !prev)} className={styles.collapseButton}>{sideMenuOpen ? ">" : "<"}</button>
      </div>
      <SideMenu
        sideMenuOpen={sideMenuOpen}
        canEdit={canEdit}
        selectedEmail={selectedEmail}
        selected={emailIndex}
      />
    </div>
  );
};

export default MessagesPage;
