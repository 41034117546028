import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow, { GrowProps } from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { RegeneratePromptType } from "../../aws/queries";

import styles from "./SplitButton.module.css";

const SplitButton: React.FC<{
  options: string[];
  fixedText?: string;
  clickHandler: (
    event: React.MouseEvent<unknown> | null,
    type?: RegeneratePromptType
  ) => void;
  clickMenuItemHandler: (
    event: React.MouseEvent<unknown> | null,
    type?: RegeneratePromptType
  ) => void;
  loading?: boolean;
  disabled?: boolean;
}> = ({
  options,
  fixedText,
  clickHandler,
  clickMenuItemHandler,
  loading = false,
  disabled = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        ref={anchorRef}
        disabled={disabled}
        aria-label="split button"
        style={{
          border: "none"
        }}
      >
        <Button
          variant="outlined"
          onClick={clickHandler}
          size="small"
          style={{
            padding: "0 18px"
          }}
        >
          {loading && <span className={styles["button--loading"]}></span>}
          <span
            style={{
              color: loading ? "transparent" : "inherit",
            }}
          >
            {fixedText} {options[selectedIndex]}
          </span>
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select prompt params"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top"
      >

        {({ TransitionProps }: any) => (
          <Grow
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem style={{
                  fontSize: "11px",
                  border: "1px solid #fff",
                  maxHeight: "10px !important",
                  margin: "0px"
                }}>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      // disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={clickMenuItemHandler}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default SplitButton;
