import { isTokenExpired } from "../helpers/tokenRefresh";
import { handleLogout } from "../helpers/logout";

interface EmailQuery {
  to: string;
  subject: string;
  queryID: string;
  textContent: string;
}

export enum RegeneratePromptType {
  ToneOfVoice = "TONE_OF_VOICE",
  MoreVerbose = "MORE_VERBOSE",
  LessVerbose = "LESS_VEBOSE",
  MoreFormal = "MORE_FORMAL",
  MoreInformal = "MORE_INFORMAL",
}

interface RegenerateResponse {
  emailId: string,
  emailBody: string | null,
  setGeneratedResponse: React.Dispatch<React.SetStateAction<string>>,
  setLoadingResponse: React.Dispatch<React.SetStateAction<boolean>>,
  type?: RegeneratePromptType
}
export const regenerateResponse = async ({ type = RegeneratePromptType.ToneOfVoice, emailId, emailBody, setGeneratedResponse, setLoadingResponse }: RegenerateResponse) => {
  if (!emailBody) {
    throw new Error("New email body cannot be empty");
  }
  const session = sessionStorage.getItem("Session");
  const idToken = JSON.parse(session ?? "").idToken.jwtToken;
  const retries = 0;
  try {
    const initialResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/emails/regenerateResponse`, {
      method: "POST",
      body: JSON.stringify({
        type,
        messageId: emailId,
        emailBody
      }),
      headers: {
        Authorization: idToken,
        "Content-Type": "application/json"
      }
    });
    if (!initialResponse.ok) {
      throw new Error("Response not okay");
    }

    const response = await initialResponse.json();
    setGeneratedResponse(response.regeneratedResponse.replaceAll("<br>", "\n"));
    setLoadingResponse(false);
    return;
  } catch (e) {
    console.log(e);
    return setTimeout(() => pollResponse(emailId, setGeneratedResponse, setLoadingResponse, retries), 5000);
  }
};

export const pollResponse = async (
  emailId: string,
  setGeneratedResponse: React.Dispatch<React.SetStateAction<string>>,
  setLoadingResponse: React.Dispatch<React.SetStateAction<boolean>>,
  retries: number,
) => {
  if (retries >= 7) {
    setLoadingResponse(false);
    return;
  }
  retries++;
  const session = sessionStorage.getItem("Session");
  const idToken = JSON.parse(session ?? "").idToken.jwtToken;
  console.log("Polling reponse");
  try {
    const initialResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/emails/regenerateResponse?emailId=${emailId}`, {
      headers: {
        Authorization: idToken
      }
    });

    const tmp = await initialResponse.json();
    setGeneratedResponse(tmp.response.replaceAll("<br>", "\n"));
    setLoadingResponse(false);
    return tmp;
  } catch (e) {
    console.log(e);
    setTimeout(() => pollResponse(emailId, setGeneratedResponse, setLoadingResponse, retries), 5000);
  }
};
