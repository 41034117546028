
export interface User {
  email: string,
  status: string,
  queue: string,
  firstName: string,
  lastName: string
  accountType: string;
}

export const getUser = (): User | null => {
  const user: string | null = sessionStorage.getItem("User");

  if (user) {
    return JSON.parse(user) as User;
  }
  return null;
};

export const userHTMLSignature = () => {

  const user = getUser();
  return `
<p><span style="font-family: verdana, geneva; font-size: 10pt;"></span><span style="font-family: verdana, geneva; font-size: 10pt;">Kind regards,</span></p>
<p><span style="color: #ff6600; font-family: verdana, geneva; font-size: 10pt;"><strong><span style="color: #000000;">${user?.firstName}</span><br><span style="color: #ff4600;">easyJet Customer Services</span></strong></span></p>
<p><span style="font-family: verdana, geneva; font-size: 10pt;"><a href="https://www.easyjet.com/en/flight-tracker" target="_blank" rel="noopener"><img src="http://www.easyjet.com/ejcms/cache/medialibrary/Images/Widgets/Flight%20Tracker/Signatures/EN-Flight-Tracker-Signature" alt="EJSignature" width="300"></a></span></p>
<p><span style="font-family: verdana, geneva; font-size: 10pt;">fly us:&nbsp;<a href="https://www.easyjet.com/" target="_blank" rel="noopener"><span style="text-decoration: underline; color: #ff6600;">easyJet.com</span><br></a>holiday with us:&nbsp;<a href="https://www.easyjet.com/en/holidays" target="_blank" rel="noopener"><span style="text-decoration: underline; color: #ff6600;">easyjet.com/holidays</span><br></a>tweet us:&nbsp;<a href="https://twitter.com/easyJet" target="_blank" rel="noopener"><span style="text-decoration: underline; color: #ff6600;">twitter.com/easyJet</span><br></a>friend us:&nbsp;<a href="https://www.facebook.com/easyJet" target="_blank" rel="noopener"><span style="text-decoration: underline; color: #ff6600;">facebook.com/easyJet</span><br></a>follow us:&nbsp;<a href="https://www.instagram.com/easyJet" target="_blank" rel="noopener"><span style="text-decoration: underline; color: #ff6600;">instagram.com/easyJet</span><br></a><span style="text-decoration: underline; color: #ff6600;"><a href="https://www.easyjet.com/en/mobile-app" target="_blank" rel="noopener"><span style="color: rgb(255,102,0); text-decoration: underline;">Mobile App</span></a></span></span></p>
<p>The information in this e-mail and any attachments is confidential and may be legally privileged. It is intended solely for the addressee(s) named above. If you are not an intended recipient, please notify the sender and delete the message and any attachments from your system. Any use, copying or disclosure of the contents of either is unauthorised unless expressly permitted. Any views expressed in this message are those of the sender unless expressly stated as to be those of easyJet. Virus checking of emails and attachments is the responsibility of the recipient. easyJet Airline Company Limited Registered in England with Registered number: 3034606 Subsidiary of easyJet Plc Registered in England with registered number: 3959649 Registered Office: Hangar 89, London Luton Airport, Luton, Bedfordshire LU2 9PF Click here to report this email as spam. </p>`;
};

export const userSignature = () => {

  const user = getUser();

  return `
Kind Regards,
${user?.firstName}
easyJet Customer Services

EJSignature
fly us: easyJet.com
holiday with us: easyJet.com/holidays
tweet us: twitter.com/easyJet.com
friend us: facebook.com/easyJet
follow us: instagram.com/easyJet
Mobile App
`;
};
