import React from "react";
import { TextField } from "@mui/material";
import styles from "./ParamsContainer.module.css";

interface ParamsContainerProps {
  bookingReference?: string;
  departureAirport?: string;
  destinationAirport?: string;
  flightNo?: string;
  canEdit: boolean;
  userEmail?: string;
  userFName?: string;
  userSurname?: string;
  customerPhoneNumber?: string;
}
const ParamsContainer: React.FC<ParamsContainerProps> = ({
  bookingReference = "",
  departureAirport = "",
  destinationAirport = "",
  flightNo = "",
  userEmail = "",
  userFName = "",
  userSurname = "",
  customerPhoneNumber= "",
  // canEdit
}) => {

  const removeEmpty = (value?: string) => {
    if (value) {
      if (value === "<empty>") {
        return "";
      } else {
        return value;
      }
    } else {
      return "";
    }
  };

  return (
    <div className={styles.parametersContainer}>
      <span className={styles.titleText}>Booking Information</span>
      <div className={styles.upperButtonsContainer}>
        <TextField
          id="firstname"
          label="First Name"
          defaultValue={removeEmpty(userFName)}
          variant="standard"
          sx={{
            "& label.Mui-focused": { color: "var(--main-color)" },
            "& .MuiInput-underline": {},
          }}
        />
        <TextField
          id="surname"
          label="Surname"
          defaultValue={removeEmpty(userSurname)}
          variant="standard"
          sx={{
            "& label.Mui-focused": { color: "var(--main-color)" },
            "& .MuiInput-underline": {},
          }}
        />
        <TextField
          id="bookingRef"
          label="Booking Reference"
          defaultValue={removeEmpty(bookingReference)}
          variant="standard"
          sx={{
            "& label.Mui-focused": { color: "var(--main-color)" },
            "& .MuiInput-underline": {},
          }}
        />
      </div>
      <div className={styles.lowerButtonsContainer}>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", flexDirection: "row", alignItems: "end" }}>
          <div className={styles.paramContainer}>
            <TextField
              id="contactno"
              variant="standard"
              label="Customer Contact No."
              value={removeEmpty(customerPhoneNumber)}
              sx={{
                "& label.Mui-focused": { color: "var(--main-color)" },
                "& .MuiInput-underline": {},
                "width": "-webkit-fill-available"
              }}
            />
          </div>
          <div className={styles.paramContainer}>
            <TextField
              id="email"
              variant="standard"
              label="Customer Email"
              defaultValue={removeEmpty(userEmail) || ""}
              sx={{
                "& label.Mui-focused": { color: "var(--main-color)" },
                "& .MuiInput-underline": {},
                "width": "-webkit-fill-available"
              }}
            />
          </div>
        </div>
        <div className={styles.paramContainer}>
          <div>Flight Date:</div>
          <input
            id="flightdate"
            placeholder="Flight Date"
            className={styles.inputBox}
            type="date"
            defaultValue=""
          />
        </div>
        <div className={styles.paramContainer}>
          <TextField
            id="departure"
            variant="standard"
            label="Departure Airport"
            defaultValue={removeEmpty(departureAirport) || ""}
            sx={{
              "& label.Mui-focused": { color: "var(--main-color)" },
              "& .MuiInput-underline": {},
              "width": "-webkit-fill-available"
            }}
          />
        </div>
        <div className={styles.paramContainer}>
          <TextField
            id="destination"
            variant="standard"
            label="Arrival Airport"
            defaultValue={removeEmpty(destinationAirport) || ""}
            sx={{
              "& label.Mui-focused": { color: "var(--main-color)" },
              "& .MuiInput-underline": {},
              "width": "-webkit-fill-available"
            }}
          />
        </div>
        <div className={styles.paramContainer}>
          <TextField
            id="flightNo"
            variant="standard"
            label="Flight Number"
            defaultValue={removeEmpty(flightNo) || ""}
            sx={{
              "& label.Mui-focused": { color: "var(--main-color)" },
              "& .MuiInput-underline": {},
              "width": "-webkit-fill-available"
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ParamsContainer;
