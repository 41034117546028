import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { Loader } from "../components/General";
import { EMAIL_ACTION_TYPE } from "../store/actions/types";
import { useAuthl } from "../contexts/authContext";

type Props = {
  children?: any;
  dispatch: React.Dispatch<{ type: EMAIL_ACTION_TYPE, payload: any }>
};

const PrivateRoute = ({ children, dispatch }: Props) => {

  // const userLoggedIn = emailState.userLoggedIn;
  const { state } = useAuthl();
  const { isLoggedIn } = state;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const updateLoggedIn = (status: boolean) => {
      dispatch({ type: EMAIL_ACTION_TYPE.LOGIN, payload: { status: status } });
    };
    try {
      const session = JSON.parse(sessionStorage.getItem("Session") ?? "");
      const { idToken, accessToken } = session;
      if (idToken && accessToken && Date.now() < idToken.payload.exp) {
        // cognitoUser.getSession(function(err: Error, session: CognitoUserSession) {

        //   if (err) {
        //     alert(err);
        //     return;
        //   }
        updateLoggedIn(true);
        // });
      } else {
        updateLoggedIn(false);
      }
      setLoading(false);
    } catch (e) {
      updateLoggedIn(false);
    }
  }, [isLoggedIn]);

  if (loading) {
    return <Loader />;
  }

  if (isLoggedIn) {
    // authorized so return child components
    return children;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
