
import React, { useEffect, useState } from "react";
import styles from "./AttachmentsContainer.module.css";

interface AttachmentContainer {
  conversation: any;
  selected: number;
}

interface Attachment {
  fileName: string,
  signedUrl: string,
}

const AttachmentContainer: React.FC<AttachmentContainer> = ({ conversation, selected }) => {

  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const getAttachments = async () => {
      if ( selected === -1 ) return;

      try {
        const session = sessionStorage.getItem("Session");
        const idToken = JSON.parse(session ?? "").idToken.jwtToken;
        const response = await fetch(process.env.REACT_APP_BASE_URL + "/conversations/emails/attachments", {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
            "Authorization": idToken
          },
          body: conversation.emails[selected].messageID
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setAttachments(data);

      } catch (error: any) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    getAttachments();
  },[selected]);

  const showAttachments = attachments.map((value: Attachment, index) => (
    <strong key={index}><a target="_blank" rel="noreferrer" href={value.signedUrl} className={styles.articleText}>{value.fileName}</a></strong>
  ));

  return (
    <>
      {
        (selected === -1)
          ? <p>Please select an email</p>
          : (attachments.length < 1)
            ? <p>The selected email has no attachments</p>
            : <div className={styles.attachmentContainer}>{showAttachments}</div>
      }
    </>
  );
};

export default AttachmentContainer;
