import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useLocation } from "react-router-dom";
import styles from "./TopMenu.module.css";
import { useAuthl } from "../../contexts/authContext";
import { updateActivityStatus, addUserToQueue } from "../../aws/dynamoDB/activity";
import { getUser, User } from "../../helpers/getCurrentUser";
import { EMAIL_ACTION_TYPE } from "../../store/actions/types";
import { handleLogout } from "../../helpers/logout";
import { unassignWorkFromList } from "../../helpers/unassignWorkFromList";

interface TopMenuProps {
  toggleModal: () => void;
  emailState: { list: any[]; userLoggedIn: boolean };
  emailDispatch: React.Dispatch<{ type: EMAIL_ACTION_TYPE; payload: any }>;
}

const TopMenu: React.FC<TopMenuProps> = ({ toggleModal, emailState, emailDispatch }) => {

  const [anchorSettingsEl, setAnchorSettingsEl] = React.useState<null | HTMLElement>(null);
  const [anchorActivityEl, setAnchorActivityEl] = React.useState<null | HTMLElement>(null);
  const [name, setName] = React.useState<string>("");
  const [error, setError] = React.useState<string | null>(null);

  const location = useLocation();
  const openActivity = Boolean(anchorActivityEl);
  const open = Boolean(anchorSettingsEl);
  const { dispatch, updateAvailability, setAssignedSwitch, setIsAssigning, isAssigning, setAssignedWorkItems } = useAuthl();

  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSettingsEl(event.currentTarget);
  };

  const user: User | null = getUser();

  const handleLogoutUser = async (): Promise<void> => {
    if (user) {
      setAssignedWorkItems(null);
      handleLogout(user);

      dispatch({ type: "LOGOUT" });
    }
  };

  const handleClose = (): void => {
    setAnchorSettingsEl(null);
    setAnchorActivityEl(null);
  };

  const handleActivityClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorActivityEl(event.currentTarget);
  };

  const returnToMain = (): void => {
    if (location.pathname.includes("email")) {
      toggleModal();
    }
  };

  const handleUpdateStatus = async (status: string) => {
    const user: User | null = getUser();
    if (user){
      //ensure that the user doesnt go busy multiple times, or available multiple times
      const work = sessionStorage.getItem("workItems");
      const workItems: any = work && JSON.parse(work);
      //if User clicks Offline, change it to Offline-LoggedOn
      let currentAvailability = status;

      if (status === "Offline"){
        currentAvailability = "Offline-loggedOn";
      }

      if (user?.status === status){
        console.log("Already this Status");
        return;
      }

      if (currentAvailability === "Available" && workItems){
        setError("You cannot go available until you unassign current manually allocated item by going offline again first.");
        setTimeout(() => {
          setError(null);
        },10000);
        return;
      }

      let assignedEmailAddress = "";
      let assignedConversationId = "";
      let conversationRecieptHandle = "";
      let holdExpire = "";

      if (workItems){
        assignedEmailAddress = workItems.emailAddress;
        assignedConversationId = workItems.conversationID;
        conversationRecieptHandle = workItems.conversationRecieptHandle;
        holdExpire = workItems.holdExpireDate;
      }

      updateActivityStatus(user.email, currentAvailability, user.queue,
        assignedEmailAddress, assignedConversationId, conversationRecieptHandle, holdExpire);

      //update the session storage User

      if (currentAvailability === "Offline-loggedOn" && workItems){
        const newList = unassignWorkFromList(emailState.list, workItems);

        emailDispatch({
          type: EMAIL_ACTION_TYPE.UPDATE_EMAILS,
          payload: { list: newList }
        });
        setAssignedSwitch((value) => !value);

        setAssignedWorkItems(null);
        sessionStorage.removeItem("workItems");
      }

      if (currentAvailability === "Available"){
        console.log("RAN ADD TO QUEUE");
        setIsAssigning(true);
        //add the user to the queue
        await addUserToQueue(user.email, user.queue);

      }

      //update frontend variables
      sessionStorage.setItem("User", JSON.stringify({ ...user, status: currentAvailability }));
      updateAvailability(status);
    }
  };

  return (
    <React.Fragment>

      { error !== null &&
        <div style={{
          position: "fixed",
          top: "0",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "orange",
          padding: "10px 20px",
          border: "1px solid black",
          zIndex: 100,
          width: "auto",
          textAlign: "center",
          marginTop: "1em"
        }}>
          <p>{error}</p>
        </div>
      }
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "var(--main-color)",
          width: "100%",
          justifyContent: "space-between",
          height: "60px",
          color: "white",
          paddingRight: "10px",
          zIndex: 10,
          boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <img
          className={styles.image}
          src="/assets/easyjet.png"
          height={"100%"}
          alt="EasyJet"
          onClick={returnToMain}
        />
        {!location.pathname.includes("login") && (
          <div className={styles.container}>
            {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
            <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleSettingsClick}
                size="small"
                style={{ borderRadius: 0 }}
                sx={{ ml: 0 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <div className={styles.profileIconContainer}>
                  <Avatar style={{ border: "1px solid black" }} sx={{ width: 32, height: 32 }}>{name !== "" ? name[0].toUpperCase() : "A"}</Avatar>
                  <div
                    className={styles.activityCircle}
                    style={{ backgroundColor: user?.status === "Available" ? "green" : user?.status === "Busy" ? "red" : "gray" }}
                  />
                </div>

                <div className={styles.nameAvailablityContainer}>
                  <p>{user?.firstName} {user?.lastName}</p>
                  <p>{user?.status}</p>
                </div>
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Box>
      <div>
        <Menu
          anchorEl={anchorSettingsEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: "\"\"",
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() => handleUpdateStatus("Available")}>
            <div
              style={{ height: 10, width: 10, backgroundColor: "#339933", borderRadius: 10, borderColor: "#000000", borderStyle: "solid", borderWidth: 1, marginRight: 15 }}
            /> Available
          </MenuItem>
          { !isAssigning
            &&
              <div>
                <MenuItem
                  onClick={() => handleUpdateStatus("Busy")}>
                  <div
                    style={{ height: 10, width: 10, backgroundColor: "#ff1f1f", borderRadius: 10, borderColor: "#000000", borderStyle: "solid", borderWidth: 1, marginRight: 15 }}
                  /> Busy
                </MenuItem>
                <MenuItem
                  onClick={() => handleUpdateStatus("Offline")}>
                  <div
                    style={{ height: 10, width: 10, backgroundColor: "#dddddd", borderRadius: 10, borderColor: "#000000", borderStyle: "solid", borderWidth: 1, marginRight: 15 }}
                  /> Appear Offline
                </MenuItem>
              </div>
          }
          {/* TODO: add menu items */}
          <Divider />
          {/* <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem> */}
          { !isAssigning &&
          <MenuItem onClick={handleLogoutUser}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
          }
        </Menu>
      </div>
    </React.Fragment>
  );
};

export default TopMenu;
