import { EMAIL_ACTION_TYPE } from "../actions/types";

const INITIAL_STATE: {list: any[], userLoggedIn: boolean} = {
  list: [],
  userLoggedIn: false
};

const emailReducer = (state = INITIAL_STATE, action: {type: EMAIL_ACTION_TYPE, payload: any }) => {
  if (action.type === "EMAILS/LOGIN") {
    return {
      ...state,
      userLoggedIn: action.payload.status
    };
  }
  if (action.type === "EMAILS/FETCH") {
    return {
      ...state,
      list: action.payload.list
    };
  }
  if (action.type === "EMAILS/ADD") {
    return {
      ...state,
      list: action.payload.list
    };
  }
  return state;
};

export default emailReducer;
