
import React, { createContext, useReducer, useState, useContext, ReactNode, useEffect } from "react";
import { AuthState, Action } from "../store/actions/types";
import authReducer from "../store/reducers/authReducer";
import { getUser, User } from "../helpers/getCurrentUser";
import { ConversationType } from "../aws/interface";

type AuthContextType = {
  state: AuthState;
  dispatch: React.Dispatch<Action>;
  userStatus: string;
  setAvailability: React.Dispatch<React.SetStateAction<string>>;
  updateAvailability: (value: string) => void;
  assignedWorkItems: any;
  setAssignedWorkItems: React.Dispatch<React.SetStateAction<any>>;
  filterSwitch: boolean;
  setFilterSwitch: React.Dispatch<React.SetStateAction<boolean>>;
  assignedSwitch: boolean;
  setAssignedSwitch: React.Dispatch<React.SetStateAction<boolean>>;
  assignNewItemSwitch: boolean;
  setAssignNewItemSwitch: React.Dispatch<React.SetStateAction<boolean>>;
  isAssigning: boolean;
  setIsAssigning: React.Dispatch<React.SetStateAction<boolean>>;
};

// Context to allow us to access the isLoggedIn variable from anywhere in the project
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuthl = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // Get the initial value from sessionStorage
  const initialLoginStatus: boolean = sessionStorage.getItem("isLoggedIn") === "true";
  const parsedUser: User | null = getUser();
  const workItems: string | null = sessionStorage.getItem("workItems");
  const parsedWorkItems: ConversationType = workItems && JSON.parse(workItems);

  const [state, dispatch] = useReducer(authReducer, { isLoggedIn: initialLoginStatus });
  const [userStatus, setAvailability] = useState<string>(parsedUser && parsedUser.status ? parsedUser.status : "Busy");
  const [assignedWorkItems, setAssignedWorkItems] = useState<ConversationType | any>(parsedWorkItems ? parsedWorkItems : null);
  const [filterSwitch, setFilterSwitch] = useState(false);
  const [assignedSwitch, setAssignedSwitch] = useState(false);
  const [assignNewItemSwitch, setAssignNewItemSwitch] = useState<boolean>(false);
  const [isAssigning, setIsAssigning] = useState(false);
  // const allUsers: any = {};
  console.log(assignedWorkItems);

  useEffect(() => {
    // Store the updated isLoggedIn value to sessionStorage whenever it changes
    sessionStorage.setItem("isLoggedIn", state.isLoggedIn.toString());
  }, [state.isLoggedIn]);

  const updateAvailability = (value: string): void => {
    setAvailability(value);
    sessionStorage.setItem("User", JSON.stringify({ ...parsedUser, status: value }));
  };

  const contextValues = {
    state,
    dispatch,
    userStatus,
    setAvailability,
    updateAvailability,
    assignedWorkItems,
    setAssignedWorkItems,
    filterSwitch,
    setFilterSwitch,
    assignedSwitch,
    setAssignedSwitch,
    assignNewItemSwitch,
    setAssignNewItemSwitch,
    isAssigning,
    setIsAssigning
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};
