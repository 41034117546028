import { isTokenExpired } from "../helpers/tokenRefresh";
import { handleLogout } from "../helpers/logout";

export const reassignToKana = async (emailId: string, escalateToEmail:string) => {

  await isTokenExpired();

  const session = sessionStorage.getItem("Session");
  const idToken = JSON.parse(session ?? "").idToken.jwtToken;

  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/emails/escalateToKana`, {
    method: "POST",
    body: JSON.stringify({ emailId, recipientEmail: escalateToEmail }),
    headers: {
      "Authorization": idToken
    }
  });

  if (!response.ok && response.status === 401) {
    const userInfo = JSON.parse(sessionStorage.getItem("User") ??"");

    await handleLogout(userInfo);
    return null;
  }

  if (!response.ok) {
    throw new Error("Error sending to KANA");
  }
};

interface UpdateEmailStatusProps {
  emailAddress: string,
  conversationId: string,
  newStatus: string,
}

export const handleRefreshToken = async () => {
  // Replace with the actual logic to get a new token
  try {
    const initSession = sessionStorage.getItem("Session");
    const session = initSession && JSON.parse(initSession);

    const body = {
      refreshToken: session.refreshToken.token
    };

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/refresh_token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();

    return data;

  } catch (error) {
    console.log(error);
  }
};

// export const updateEmailStatusLambda = async ({ emailAddress, conversationId, newStatus }: UpdateEmailStatusProps) => {

//   const session = sessionStorage.getItem("Session");
//   const idToken = JSON.parse(session ?? "").idToken.jwtToken;

//   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/emails/status `, {
//     method: "PUT",
//     body: JSON.stringify({ emailAddress, conversationId, newStatus }),
//     headers: {
//       "Authorization": idToken
//     }
//   });
//   if (!response.ok) {
//     throw new Error("Error update email status");
//   }
// };
