
import { AuthState, Action } from "../actions/types";

//reducer to keep track of whether a user is logged in or not
function authReducer(state: AuthState, action: Action): AuthState {
  switch (action.type) {
  case "LOGIN":
    return { isLoggedIn: true };
  case "LOGOUT":
    return { isLoggedIn: false };
  default:
    throw new Error("Invalid action type for authReducer");
  }
}

export default authReducer;
