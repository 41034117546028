import { useState, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./App.css";
import { Modal } from "./components/General";
import emailReducer from "./store/reducers/emailsReducer";
import { EMAIL_ACTION_TYPE } from "./store/actions/types";
import { TopMenu, SideMenu } from "./components/Navigation";
import Router from "./routes/Router";
import { getConversations, pollAssignedItems, confirmReceived } from "./aws/dynamoDB/emails";
import { GlobalStyles } from "@mui/system";
import IdleDetector from "./helpers/idleDetector";
import { getUser, User } from "./helpers/getCurrentUser";
import { useAuthl } from "./contexts/authContext";
import { handleLogout } from "./helpers/logout";
import { handleEmailVisibilityIncrease } from "./helpers/handleEmailVisibilityIncrease";
import { buildCurrentQuery } from "./helpers/buildCurrentQuery";
import LogoutNavigate from "./routes/logoutRedirect";

const App = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [isPolling, setIsPolling] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [firstPoll, setFirstPoll] = useState(true);

  const { dispatch, setAssignedWorkItems, userStatus, filterSwitch, setAssignedSwitch, setIsAssigning, assignNewItemSwitch } = useAuthl();
  const [emailState, emailDispatch] = useReducer(emailReducer, {
    list: [],
    userLoggedIn: false,
  });
  useEffect(() => {
    const user = getUser();

    if (!user) {
      navigate("/login");
    }
  }, []);

  const addWorkToEmailsTable = (conversation: any) => {
    // Check if conversation contains emailAddress and conversationID
    if (conversation && conversation.emailAddress && conversation.conversationID) {

      let inArrayFlag = false;
      let updatedValue;

      // Create a new list without the existing conversation
      const newList = emailState.list.filter((value: any) => {
        if (value.conversationID === conversation.conversationID &&
          value.emailAddress === conversation.emailAddress) {
          inArrayFlag = true;
          updatedValue = {
            ...value,
            assignedTo: conversation.assignedTo,
            conversationRecieptHandle: conversation.conversationRecieptHandle,
            assignDateStart: conversation.assignDateStart,
            holdExpireDate: conversation.holdExpireDate
          };
          return false; // Do not include this in newList
        }
        return true;
      });

      // Add the conversation to the top of the list
      if (inArrayFlag) {
        newList.unshift(updatedValue);
      } else {
        newList.unshift(conversation); // Add new conversation at the beginning
      }

      const currentTimestamp = Date.now(); // Current timestamp in milliseconds
      const twentyFiveMinutesInMilliseconds = 25 * 60000; // 25 minutes in milliseconds
      const futureTimestamp = currentTimestamp + twentyFiveMinutesInMilliseconds;

      const sessionWork = {
        ...conversation,
        expireTime: futureTimestamp
      };
      setAssignedWorkItems(sessionWork);
      sessionStorage.setItem("workItems", JSON.stringify(sessionWork));
      emailDispatch({
        type: EMAIL_ACTION_TYPE.UPDATE_EMAILS,
        payload: { list: newList }
      });
      setAssignedSwitch((value) => !value);
      setIsAssigning(false);
    }
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const pollWork = async () => {
      // Prevent polling if the user is not available or has work items

      const work = sessionStorage.getItem("workItems");
      const workItems: any = work && JSON.parse(work);

      if (userStatus !== "Available" || (workItems && workItems !== "assign")) {
        setIsPolling(false);
        return;
      }

      if (firstPoll) {
        await setTimeout(() => {
          //if its the first poll wait 1 second to give the allocation some time to allcoate
          setFirstPoll(false);
        }, 2500);
      }

      console.log("CURRENTLY POLLING");
      // If we're not already polling, start polling every 2.5 seconds
      if (!isPolling) {
        setIsPolling(true);
      }

      try {
        const conversation = await pollAssignedItems();
        if (conversation === false || conversation === undefined) {
          timeoutId = setTimeout(pollWork, 3500);
        } else {
          addWorkToEmailsTable(conversation);
          confirmReceived(conversation.emailAddress, conversation.conversationID, true);
          setIsPolling(false);
          setFirstPoll(true);
        }
      } catch (error) {
        console.error("An error occurred while polling:", error);
        // In case of error, you might want to retry after a delay
        timeoutId = setTimeout(pollWork, 5000);
      }
    };

    pollWork();

    // Cleanup function to clear the timeout if the component is unmounted
    // or if the userStatus or assignedWorkItems change
    return () => {
      clearTimeout(timeoutId);
    };
  }, [userStatus, assignNewItemSwitch, emailState.list]);

  //gets the email table data and updates emailList
  useEffect(() => {
    const query = sessionStorage.getItem("Query");
    const parsedQuery = query && JSON.parse(query) || null;
    const user: User | null = getUser();
    const work = sessionStorage.getItem("workItems");
    const workItems: any = work && JSON.parse(work);

    if (parsedQuery !== null) {
      const callGetConversations = async () => {

        const emailList = await getConversations();

        emailDispatch({
          type: EMAIL_ACTION_TYPE.UPDATE_EMAILS,
          payload: { list: emailList },
        });

        setAssignedSwitch((value) => !value);
      };

      callGetConversations();
    }

  }, [filterSwitch]);

  const toggleModal = (): void => setOpenModal((prev) => !prev);

  const onConfirm = (): void => {

    const query = buildCurrentQuery();

    navigate(`/${query}`);
    toggleModal();
  };

  //when a user is idle, log the user out
  const handleIdle = async (): Promise<void> => {
    await handleUnassignItems();
  };

  const handleUnassignItems = async (): Promise<void> => {

    const user: User | null = getUser();

    if (user) {
      setAssignedWorkItems(null);
      await handleLogout(user);
      dispatch({ type: "LOGOUT" });
    }
  };

  return (
    <>
      <IdleDetector onIdle={handleIdle} />
      <GlobalStyles styles={{}} />
      <Modal
        open={openModal}
        handleClose={toggleModal}
        onConfirm={onConfirm}
        subtitle="Are you sure you want to navigate away from this page without saving?"
      />
      <TopMenu toggleModal={toggleModal} emailState={emailState} emailDispatch={emailDispatch} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "-webkit-fill-available",
          paddingTop: "60px",
          width: "-webkit-fill-available",
        }}
      >
        {!location.pathname.includes("login") && (
          <SideMenu toggleModal={toggleModal} />
        )}
        <LogoutNavigate />

        <Router emailState={emailState} emailDispatch={emailDispatch} />
      </div>
    </>
  );
};

export default App;
