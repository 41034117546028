import React, { useState, useEffect } from "react";
import styles from "./SideMenu.module.css";
import ParamsContainer from "./ParamsContainer";
// import ActionsContainer from "./ActionsContainer";
// import NewCommentsContainer from "./NewCommentsContainer";
// import ShowCommentsContainer from "./ShowCommentsContainer";
// import CommentsTabs from "./CommentsTabs";
import { getUser, User } from "../../helpers/getCurrentUser";
// import { updateEmailComments } from "../../aws/dynamoDB/emails";
import KnowledgeArticleContainer from "./knowledgeArticlesContainer";
import AttachmentContainer from "./AttachmentsContainer";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { styled } from "@mui/material/styles";
import OtherTabs from "./OtherTabs";
import IntentsContainer from "./IntentsContainer";

interface SideMenuProps {
  sideMenuOpen: boolean;
  selectedEmail: any;
  canEdit: boolean;
  selected: number;
}

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const SideMenu: React.FC<SideMenuProps> = ({ sideMenuOpen, selectedEmail, selected }) => {
  // Icons for Ratings code
  const customIcons = [{
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: "Very Dissatisfied",
  },
  {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: "Dissatisfied",
  },
  {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: "Neutral",
  },
  {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: "Satisfied",
  },
  {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: "Very Satisfied",
  }];

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{ customIcons[value - 1].icon}</span>;
  }

  const [ratingValue, setRatingValue] = useState(selectedEmail.customerSatisfaction || 0);
  const [showMustSelectEmail, setShowMustSelectEmail] = useState(false);
  const [commentsTabValue, setCommentsTabValue] = useState("view");
  const [otherTabValue, setOtherTabValue] = useState("knowledge");

  const dateStringify = (date: Date = new Date()): string => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    let dateString = "";
    dateString = String(date.getDate()) + " " +
      String(months[date.getMonth()]) + " " +
      String(date.getFullYear()) + " at " +
      (date.getHours() < 10 ? "0" : "") + String(date.getHours()) + ":" +
      (date.getMinutes() < 10 ? "0" : "") + String(date.getMinutes()) + ":" +
      (date.getSeconds() < 10 ? "0" : "") + String(date.getSeconds()) + " UTC" +
      (date.getTimezoneOffset() > 0 ? "" : "+") +
      String((-date.getTimezoneOffset()) / 60);

    return dateString;
  };

  const onSendComment = (comment: string) => {
    // Get the email being commented on

    if (!comment) {
      return;
    }

    if (selectedEmail.emails) {
      const selected = selectedEmail.emails.findIndex((email: any, index: any) => {
        return email.selected;
      });
    }

    // If no email is selected, show an error
    if (selected === -1) {
      setShowMustSelectEmail(true);
      setTimeout(() => {
        setShowMustSelectEmail(false);
      }, 5000);
    } else {
      // Send the comment to DynamoDB. Currently a mock object
      // comments may not exist. We can check choose between "ADD" and "SET" commands.
      let userEmail;
      // onAuthStateChanged(auth, (user) => {
      const user = getUser();
      if (user) {
        if (user.email) {
          userEmail = user.email;

          if (selectedEmail.emails[selected] && selectedEmail.emails[selected].comments) {
            selectedEmail.emails[selected]["comments"].push(JSON.stringify({
              comment: comment.replaceAll("\n", "<br>"),
              dateTime: dateStringify(),
              user: userEmail
            }));
            // updateEmailComments(selectedEmail.id, String(selected), selectedEmail.emails[selected].comments);
          } else {
            const currentComments = [JSON.stringify({
              comment: comment.replaceAll("\n", "<br>"),
              dateTime: dateStringify(),
              user: userEmail
            })];
            selectedEmail.emails[selected].comments = currentComments;
            // updateEmailComments(selectedEmail.id, String(selected), selectedEmail.emails[selected].comments);
          }

        }
      }
      setCommentsTabValue("view");
      // });
    }
  };

  const knowledgeArticles = (selectedEmail && selectedEmail.emails && selected >= 0) ? selectedEmail.emails[selected]?.knowledgeArticles :[];
  useEffect(() => {
    if (selectedEmail.emails) {
      const thatEmail = selectedEmail.emails.findIndex((email: any, index: any) => {
        return email.selected;
      });
    }
  }, [selected]);

  const user: User | null = getUser();
  const currentEmail: string | null = user ? user.email : null;
  const canEdit: boolean = selectedEmail.assignedTo === currentEmail || selectedEmail.assignedTo === "";
  const emailStatus = selectedEmail.status === "Open" && selectedEmail.assignedTo === currentEmail ?
    "In Progress" : selectedEmail.status;
  return (
    <div style={sideMenuOpen ? {} : { maxWidth: 0, width: 0, minWidth: 0, opacity: 0, padding: 0 }} className={styles.container}>
      {(selectedEmail.assignedTo || selectedEmail.resolvedBy) &&
        <div className={styles.assignedToContainer}>
          {/* <div
            className={styles.assignedToStatus}
            style={selectedEmail.resolvedBy ? { backgroundColor: "black", opacity: 90 } : {}}
          >
            {(selectedEmail.assigendTo !== "" && selectedEmail.resolvedBy === "") ? "In Progress By" : "Closed By"}

          </div> */}
          <div className={styles[emailStatus.replace(" ", "")]}>{emailStatus}</div>
          <div className={styles.assignedToName}>
            <p>{selectedEmail.assignedTo ? selectedEmail.assignedTo : selectedEmail.resolvedBy}</p>
            <p>{selectedEmail.assignedTo ? selectedEmail.assignedTo : selectedEmail.resolvedBy}</p>
          </div>
        </div>
      }
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: 5 }}>
        <strong>Customer Sentiment</strong>
        { ratingValue === 0 ? <p>No rating received</p> :
          <StyledRating
            name="user-rating-selected-only"
            value={ratingValue}
            highlightSelectedOnly
            readOnly
            IconContainerComponent={IconContainer}
          />
        }
      </div>
      {/* <ActionsContainer onChange={onChange} suggestedRemediation={String(lastUserEmail.suggestedRemediation) || "null"} /> */}

      {/* <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <CommentsTabs current={commentsTabValue} onChange={(value: string) => setCommentsTabValue(value)} />

        {commentsTabValue === "new" && <NewCommentsContainer onSendComment={onSendComment} />}
        {commentsTabValue === "view" && <ShowCommentsContainer selectedEmail={selectedEmail} selected={selected} />}
        <div className={showMustSelectEmail ? styles.visibleError : styles.invisibleError}>
          <div>Please Select an Email to leave a comment</div>
        </div>
      </div> */}
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <OtherTabs current={otherTabValue} onChange={(value: string) => setOtherTabValue(value)} />

        {otherTabValue === "knowledge" && <KnowledgeArticleContainer knowledgeArticles={knowledgeArticles} selected={selected} />}
        {otherTabValue === "attachments" && <AttachmentContainer conversation={selectedEmail} selected={selected}/>}
      </div>

      <ParamsContainer
        key={selectedEmail.bookingRef}
        bookingReference={selectedEmail?.bookingRef}
        departureAirport={selectedEmail?.departureAirport}
        destinationAirport={selectedEmail?.arrivalAirport}
        flightNo={selectedEmail?.flightNumber}
        userEmail={selectedEmail?.emailAddress?.split("<")[1]?.split(">")[0]}
        userFName={(typeof selectedEmail?.customerName === "string") ? selectedEmail?.customerName?.split(" ")[0] : ""}
        userSurname={(typeof selectedEmail?.customerName === "string") ? selectedEmail?.customerName?.split(" ")[1] : ""}
        canEdit={canEdit}
        customerPhoneNumber={selectedEmail?.customerPhoneNumber}
      // suggestedRemediation={lastUserEmail?.suggestedRemediation || ""}
      />
      <IntentsContainer selectedEmail={selectedEmail} />
    </div>
  );
};

export default SideMenu;
