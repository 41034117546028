import { Routes, Route } from "react-router-dom";
import { EmailsMenuPage, MessagesPage, LoginPage } from "../pages";
import PrivateRoute from "./PrivateRoute";
import { EMAIL_ACTION_TYPE } from "../store/actions/types";
import ManageAccountsPage from "../pages/ManageAccountsPage";

const Router = ({
  emailState,
  emailDispatch,
}: {
  emailState: { list: any[]; userLoggedIn: boolean };
  emailDispatch: React.Dispatch<{ type: EMAIL_ACTION_TYPE; payload: any }>;
}) => {

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <PrivateRoute dispatch={emailDispatch}>
            <EmailsMenuPage
              emailState={emailState}
              emailDispatch={emailDispatch}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/email/:id"
        element={
          <PrivateRoute dispatch={emailDispatch}>
            <MessagesPage emailState={emailState} emailDispatch={emailDispatch} />
          </PrivateRoute>
        }
      />
      <Route
        path="/manage-accounts"
        element={
          <PrivateRoute dispatch={emailDispatch}>
            <ManageAccountsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/manage-reports"
        element={
          <PrivateRoute dispatch={emailDispatch}>
            {"Manage Reports Page"}
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default Router;
