import React, { Dispatch, useState, useEffect } from "react";
import { EmailsMenu } from "../components/EmailsMenuPage";
import styles from "./EmailsMenuPage.module.css";
import { useSearchParams } from "react-router-dom";
import EmailTable from "../components/General/Table/EmailTable";
import { EMAIL_ACTION_TYPE } from "../store/actions/types";
import { ConversationType } from "../aws/interface";

interface Props {
  emailState: { list: any[], userLoggedIn: boolean };
  emailDispatch: (action: { type: EMAIL_ACTION_TYPE; payload: any }) => void
}

const EmailsMenuPage: React.FC<Props> = ({ emailState, emailDispatch }) => {
  const [emailsMenuOpen, setEmailsMenuOpen] = useState(true);
  const [searchParams] = useSearchParams();
  let rows: ConversationType[] = emailState.list;

  const updateRows = (newList: ConversationType[]): void => {
    // setRowState(newList);
  };

  useEffect(() => {
    rows = emailState.list;
  }, [emailState.list]);

  return (
    <div className={styles.container}>
      <EmailsMenu emailsMenuOpen={emailsMenuOpen} rows={rows} onSearchHandler={updateRows} /> {/* filters={filters} /> */}
      <div
        className={styles["contentOuterContainer"]}
        style={emailsMenuOpen ? { left: "390px" } : { left: "79px" }}
      >
        {/* NEW */}
        <div style={emailsMenuOpen ? {} : { width: 30 }} className={styles.collapseButtonContainer}>
          <button title={"collapseButton"} style={emailsMenuOpen ? {} : { width: 30 }} className={styles.collapseButton} onClick={() => setEmailsMenuOpen(prev => !prev)}>{emailsMenuOpen ? "<" : ">"}</button>
        </div>
        {/* NEW END */}
        <div className={styles.contentInnerContainer}>
          <div className={styles.fillHeight}>
            <div className={styles.gridContainer}>
              <div
                style={{
                  padding: "10px 20px",
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available"
                }}
              >
                <div className={styles.gridInnerContainer}>
                  <EmailTable
                    rows={rows}
                    filters={Array.from(searchParams.keys())}
                    emailDispatch={emailDispatch}
                    emailState={emailState}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailsMenuPage;
