import React, { useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import EmailsMenuItem from "./EmailsMenuItem";
import { STATUS } from "../../models/types";
import { Button, TextField } from "@mui/material";
import styles from "./EmailsMenu.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Refresh, Search, TextRotationAngledown } from "@mui/icons-material";
import { getUser, User } from "../../helpers/getCurrentUser";
import { ConversationType } from "../../aws/interface";
import { parse } from "path";
import { statusDictionary, StatusKey } from "../../helpers/statusDict";
import { useAuthl } from "../../contexts/authContext";

interface EmailsMenuProps {
  emailsMenuOpen: boolean;
  rows: ConversationType[];
  onSearchHandler: (rows: ConversationType[]) => void;
}

/**
 *
 * @param s1 string from search box.
 * @param s2 String from searched item, to be compared against.
 * @returns an boolean for whether s1 is a substring of s2.
 */
const searchFor = (s1: string, s2: string): boolean => {
  return s2.includes(s1);
};

const EmailsMenu: React.FC<EmailsMenuProps> = ({ emailsMenuOpen, rows }) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [showStatusFilters, setShowStatusFilters] = useState(false);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  //Search By Parameters
  const [conversationId, setConversationId] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [destination, setDestination] = useState<string>("");
  const [sender, setSender] = useState<string>("");
  const [body, setBody] = useState<string>("");

  //timestamps
  const [createdAtFrom, setCreatedAtFrom] = useState("");
  const [createdAtTo, setCretedAtTo] = useState("");
  const [lastModifiedFrom, setLastModifiedFrom] = useState("");
  const [lastModifiedTo, setLastModifiedTo] = useState("");

  const [isReset, setIsReset] = useState(false);

  const user: User | null = getUser();
  const currentEmail: string | null = user ? user.email : null;
  const { setFilterSwitch } = useAuthl();

  const handleFilterReset = () => {
    setConversationId("");
    setSubject("");
    setDestination("");
    setSender("");

    setCreatedAtFrom("");
    setCretedAtTo("");
    setLastModifiedFrom("");
    setLastModifiedTo("");

    setIsReset((value) => !value);
  };

  useEffect(() => {
    handleSearch();
  }, [isReset]);

  /**
   * @private
   * @param value Filter parameter
   */
  const filterFn = (value: string): void => {

    //updates the local state that contains the query to pass to the getConversations function
    if (value === "InProgress") {
      value = "In Progress";
    }

    const qry = sessionStorage.getItem("Query");
    const query = qry && JSON.parse(qry);

    let queryArray = query?.queryArray;
    let queryString = query?.queryString;

    const filter = statusDictionary[value as keyof typeof statusDictionary];

    if (value === "assignedTo") {
      if (queryArray?.includes("assignedto")) {
        queryArray = queryArray?.filter((value: string) => value !== "assignedto");
        queryString = queryString?.replace(`&assignedTo=${currentEmail}`, "");
        queryString = queryString?.replace(`?assignedTo=${currentEmail}`, "");
        queryString = queryString?.replace("conversations&", "conversations?");
      } else {
        queryArray.push("assignedto");
        queryString = queryString + `&assignedTo=${currentEmail}`;
        queryString = queryString?.replace("conversations&", "conversations?");
      }
    } else {
      if (queryArray.includes(filter)) {
        queryArray = queryArray.filter((value: string) => value !== filter);
        queryString = queryString.replace(`&status=${value}`, "");
        queryString = queryString.replace(`?status=${value}`, "");
        queryString = queryString.replace("conversations&", "conversations?");
      } else {
        queryArray.push(filter);
        queryString = queryString + `&status=${value}`;
        queryString = queryString.replace("conversations&", "conversations?");
      }
    }

    const paramsStatus = statusDictionary[value as StatusKey];
    //update params
    setSearchParams((prev) => {
      if (!prev.has(paramsStatus)) {
        prev.set(paramsStatus, "true");
      } else prev.delete(paramsStatus);
      return prev;
    });

    sessionStorage.setItem("Query", JSON.stringify({ queryString: queryString, queryArray: queryArray }));
    setFilterSwitch((value: boolean) => !value);
  };

  const handleBodySearch = async (current: any[]) => {
    console.log("Ran");
  };

  //conversations?status=Open&conversationId=ABC&status=losed
  const handleSearch = async () => {
    const qry = sessionStorage.getItem("Query") || JSON.stringify({ queryString: "", queryArray: [] });
    const query = qry && JSON.parse(qry);

    const queryArray: string[] = query?.queryArray;
    let queryString: string = query?.queryString;

    const updateQueryString = (paramName: string, value: string) => {
      const regex = new RegExp(`([&?])${paramName}=[^&]*`, "g");
      if (value !== "") {
        if (queryString.includes(`${paramName}=`)) {
          queryString = queryString?.replace(regex, `$1${paramName}=${value}`);
        } else {
          if (queryString.includes("?")) {
            queryString += `&${paramName}=${value}`;
          } else {
            queryString += `?${paramName}=${value}`;
          }
        }
      } else {
        queryString = queryString?.replace(regex, "$1");
        if (queryString?.endsWith("&")) {
          queryString = queryString?.slice(0, -1);
        }
        if (queryString.endsWith("?")) {
          queryString = queryString?.slice(0, -1);
        }
      }
    };

    updateQueryString("conversationId", conversationId);
    updateQueryString("subject", subject);
    updateQueryString("destination", destination);
    updateQueryString("sender", sender);

    const createTimestamp = (date: string): string => {
      const dateObject = new Date(date);
      const timestamp = dateObject.getTime();

      let time = JSON.stringify(timestamp);

      if (time === "null") {
        time = "";
      }
      return time;
    };

    updateQueryString("createdAtFrom", createTimestamp(createdAtFrom));
    updateQueryString("createdAtTo", createTimestamp(createdAtTo));
    updateQueryString("lastUpdatedFrom", createTimestamp(lastModifiedFrom));
    updateQueryString("lastUpdatedTo", createTimestamp(lastModifiedTo));

    sessionStorage.setItem("Query", JSON.stringify({ queryString: queryString, queryArray: queryArray }));
    setFilterSwitch((value) => !value);
  };

  const searchOnReturn = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.which === 13) {
      e.preventDefault();
      handleSearch();
    }
  };

  const textInputStyleOverride = {
    "& label.Mui-focused": { color: "var(--main-color)" },
    "marginLeft": "20px",
    "right": 0,
    "marginTop": "10px",
    "marginRight": "20px",
    "width": "-webkit-fill-available",
    "display": "flex"
  };

  const mainStyle: React.CSSProperties = { display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" };

  return (
    <div className={styles.mainContainer} title={"emailsMenu"} style={emailsMenuOpen ? mainStyle : { maxWidth: "0px", display: "none", ...mainStyle }}>
      <div className={styles.mainContainer}>
        {/* <div className={styles.titleContainer}>Emails</div> */}
        {/* <div className={styles.searchBarContainer}> */}
        {/* <div className={styles.searchBox}>
              <div className={styles.searchBoxInner}>
              <SearchIcon className={styles.searchIcon} />
              <input
                type="text"
                placeholder="Search in all emails..."
                className={styles.searchBoxInput}
                ref={searchRef}
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
              />
            </div>
          </div> */}
        {/* </div> */}
        <div className={styles.optionsContainer}>
          <div className={styles.searchByContainer}>
            <span className={styles.titleContainer}>Search By</span>
            <Button sx={{ width: "-webkit-fill-available" }} onClick={handleFilterReset}>
              Reset
            </Button>
          </div>
          <div className={styles.container}>
            <TextField
              variant="standard"
              label={"Reference ID"}
              fullWidth
              className={styles.searchBox}
              value={conversationId}
              onChange={(e) => setConversationId(e.target.value)}
              onKeyDown={searchOnReturn}
              sx={textInputStyleOverride}
            />
            <TextField
              variant="standard"
              label={"Subject"}
              fullWidth
              value={subject}
              className={styles.searchBox}
              onChange={(e) => setSubject(e.target.value)}
              onKeyDown={searchOnReturn}
              sx={textInputStyleOverride}
            />
            <TextField
              variant="standard"
              label={"Departure Airport"}
              fullWidth
              value={destination}
              className={styles.searchBox}
              onChange={(e) => setDestination(e.target.value)}
              onKeyDown={searchOnReturn}
              sx={textInputStyleOverride}
            />
            <TextField
              variant="standard"
              label={"Sender"}
              fullWidth
              value={sender}
              className={styles.searchBox}
              onChange={(e) => setSender(e.target.value)}
              onKeyDown={searchOnReturn}
              sx={textInputStyleOverride}
            />
            {/* <TextField
              variant="standard"
              label={"Email Body"}
              fullWidth
              value={body}
              className={styles.searchBox}
              onChange={(e) => setBody(e.target.value)}
              onKeyDown={searchOnReturn}
              sx={textInputStyleOverride}
            /> */}
          </div>
          <div className={styles.container}>
            <p style={{ paddingLeft: 20, marginBottom: -5 }}>Creation Date: </p>
            <div className={styles.datePicker}>
              <p>{!createdAtFrom && "From:"} </p>
              <input
                type="datetime-local"
                title="createdFrom"
                value={createdAtFrom}
                className={styles.dateButton}
                onChange={(e) => setCreatedAtFrom(e.target.value)}
              />
              <p>{!createdAtTo && "To:"} </p>
              <input
                type="datetime-local"
                title="createdTo"
                value={createdAtTo}
                className={styles.dateButton}
                onChange={(e) => setCretedAtTo(e.target.value)}
              />
            </div>
            <p style={{ paddingLeft: 20, marginBottom: -5 }}>Last Modified: </p>
            <div className={styles.datePicker}>
              <p>{!lastModifiedFrom && "From:"} </p>
              <input
                type="datetime-local"
                title="modFrom"
                value={lastModifiedFrom}
                className={styles.dateButton}
                onChange={(e) => setLastModifiedFrom(e.target.value)}
              />
              <p>{!lastModifiedTo && "To:"}</p>
              <input
                type="datetime-local"
                title="modTo"
                value={lastModifiedTo}
                className={styles.dateButton}
                onChange={(e) => setLastModifiedTo(e.target.value)}
              />
            </div>
          </div>

          {/* ["creation", "Creation Date"], ["modified", "Last Modified"] */}
        </div>
        <Button sx={{ width: "-webkit-fill-available" }} onClick={() => setShowStatusFilters(prev => !prev)}>
          Status Filters
        </Button>
        <div className={styles.optionsContainer}>

          <div style={showStatusFilters ? { maxHeight: "400px" } : { maxHeight: 0 }} className={styles.section}>
            {Object.entries(STATUS).map(([key, value]) => (
              <EmailsMenuItem
                text={value}
                key={key}
                // number={rows?.filter((item) => item?.status.includes(value)).length}
                onClick={(text) => filterFn(text)}
                searchParams={searchParams}
              />
            ))}
          </div>
          <div className={styles.separator}>
            <div className={styles.rectangle} />
          </div>
          <div className={styles.section}>
            <EmailsMenuItem
              text="My Work Items"
              // number={rows?.filter((item) => item?.assignedTo === currentEmail).length}
              onClick={() => filterFn("assignedTo")}
              searchParams={searchParams}
            />
          </div>
          <div className={styles.separator}>
            <div className={styles.rectangle} />
          </div>
        </div>
      </div>
      <Button variant="outlined"
        title="searchButton"
        sx={{ display: "flex", position: "flex-end", bottom: "0px", m: "20px" }}
        disabled={searchLoading} onClick={handleSearch}
      >
        {searchLoading ? <Refresh className={styles.loading} fontSize="large" /> : <Search fontSize="large" />}
      </Button>
    </div>
  );
};

export default EmailsMenu;
