
import { handleLogout } from "./logout";
import { getUser } from "./getCurrentUser";
import { handleRefreshToken } from "../aws/requests";
import { CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken } from "amazon-cognito-identity-js";

export const isTokenExpired = async () => {
  const user = getUser();

  if (!user){
    console.log("RAN DISPLATCH EVENT");
    window.dispatchEvent(new CustomEvent("log-user-out"));
    return;
  }

  const initSession = sessionStorage.getItem("Session");
  const session = initSession && JSON.parse(initSession);

  if (!session){
    return;
  }

  const token = session?.accessToken;
  const tokenExp = token?.payload.exp;

  if (!token){
    handleLogout(user);
  }

  const currentTime = Math.floor(Date.now() / 1000);

  if (currentTime >= tokenExp){
    const newTokens = await handleRefreshToken();

    const idToken = new CognitoIdToken({ IdToken: newTokens.idToken as string });
    const accessToken = new CognitoAccessToken({ AccessToken: newTokens.accessToken as string });
    const refreshToken = new CognitoRefreshToken({ RefreshToken: session.refreshToken.token as string });
    const ses = new CognitoUserSession({ IdToken: idToken, AccessToken: accessToken, RefreshToken: refreshToken });

    sessionStorage.setItem("Session", JSON.stringify(ses));
    console.log("ACCESS TOKEN REFRESHED");
  }

};
