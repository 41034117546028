import React from "react";
import styles from "./OtherTabs.module.css";

export default function OtherTabs({ current, onChange }: { current: any, onChange: any }) {
  return <div className={styles.container}>
    <button className={current === "knowledge" ? styles.chosen : styles.notChosen} onClick={() => onChange("knowledge")}>
      Knowledge Articles
    </button>
    <button className={current === "attachments" ? styles.chosen : styles.notChosen} onClick={() => onChange("attachments")}>
      Attachments
    </button>
  </div>;
}
