import React, { useEffect, useState, FC } from "react";

interface IdleDetectorProps {
    onIdle: () => void;
}

const IdleDetector: FC<IdleDetectorProps> = ({ onIdle }) => {
  const TIMEOUT_IN_MINUTES = 30;
  const IDLE_TIMEOUT: number = TIMEOUT_IN_MINUTES * 60 * 1000; // 10 minutes in milliseconds
  const [lastActivity, setLastActivity] = useState<number>(Date.now());

  const resetTimer = () => {
    setLastActivity(Date.now());
  };

  useEffect(() => {
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keypress", resetTimer);

    return () => {
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keypress", resetTimer);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() - lastActivity > IDLE_TIMEOUT) {
        onIdle();
        setLastActivity(Date.now()); // To avoid repeated triggers during prolonged inactivity.
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastActivity, onIdle]);

  return null;
};

export default IdleDetector;
