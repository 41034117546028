
export const unassignWorkFromList = (workList: any, workItem: any) => {

  const initQuery = sessionStorage.getItem("Query");
  const queryArray = initQuery && JSON.parse(initQuery).queryArray;

  const flag = queryArray.includes("assignedto");
  let newList;

  if (flag) {
    newList = workList.filter((value: any) => {
      if (value.conversationID === workItem.conversationID && value.emailAddress === workItem.emailAddress) return;
      return value;
    });
  } else{
    newList = workList.map((value: any) => {
      if (value.conversationID === workItem.conversationID && value.emailAddress === workItem.emailAddress) {
        return {
          ...value,
          assignedTo: ""
        };
      }
      return value;
    });
  }
  return newList;
};
