
import { User } from "./getCurrentUser";
import { updateActivityStatus } from "../aws/dynamoDB/activity";

export const handleLogout = async (user: User): Promise<void> => {

  const work = sessionStorage.getItem("workItems");
  const workItems: any = work && JSON.parse(work);

  sessionStorage.setItem("isLoggedIn", JSON.stringify(false));

  let assignedEmailAddress = "";
  let assignedConversationId = "";
  let conversationRecieptHandle = "";
  let holdExpire = "";

  if (workItems){
    assignedEmailAddress = workItems.emailAddress;
    assignedConversationId = workItems.conversationID;
    conversationRecieptHandle = workItems.conversationRecieptHandle;
    holdExpire = workItems.holdExpireDate;
  }

  const session = JSON.parse(sessionStorage.getItem("Session") ?? "");
  const idToken = session.idToken.jwtToken;
  const accessToken = session.accessToken.jwtToken;

  await updateActivityStatus(user.email, "Offline-loggedOff",
    user.queue, assignedEmailAddress, assignedConversationId, conversationRecieptHandle, holdExpire);

  await fetch(process.env.REACT_APP_BASE_URL + "/users/logout", {
    method: "POST",
    headers: {
      "Authorization": idToken
    },
    body: JSON.stringify({ token: accessToken })
  }).catch((e) => console.log("Error loging out: ", e));

  sessionStorage.clear();
};
