import { getUser, User } from "../../helpers/getCurrentUser";
import { ConversationType } from "../interface";
import { handleLogout } from "../../helpers/logout";
import { isTokenExpired } from "../../helpers/tokenRefresh";
import { FileType } from "../../models/emailData.types";

export const getConversations = async (): Promise<ConversationType[] | null> => {

  await isTokenExpired();

  const qry = sessionStorage.getItem("Query") || JSON.stringify({});
  const query = qry && JSON.parse(qry);

  const session = sessionStorage.getItem("Session");
  const idToken = session && JSON.parse(session).idToken.jwtToken;
  const queryString = query.queryString;

  if (queryString === "conversations") {
    return [];
  }

  let transportQuery = queryString?.split("?")[1];

  const params = new URLSearchParams(transportQuery);

  let subject: string | null = "";
  let destination: string | null = "";
  let sender: string | null = "";
  let assignedTo: string | null = "";

  if (params.has("subject")) {
    subject = params.get("subject");
    params.delete("subject");
  }

  if (params.has("destination")) {
    destination = params.get("destination");
    params.delete("destination");
  }

  if (params.has("sender")) {
    sender = params.get("sender");
    params.delete("sender");
  }

  if (params.has("assignedTo")) {
    assignedTo = params.get("assignedTo");
    params.delete("assignedTo");
  }

  const user: User | null = getUser();
  const initWorkItem = sessionStorage.getItem("workItems");
  const workItem = initWorkItem && JSON.parse(initWorkItem);
  let bodyWorkItem = null;

  if (workItem) {
    bodyWorkItem = workItem;
  }

  const body = {
    subject: subject,
    destination: destination,
    sender: sender,
    assignedTo: assignedTo,
    userEmailAddress: user?.email,
    workItem: bodyWorkItem
  };

  let queue = user?.queue;
  if (queue) {
    queue = queue.charAt(0).toUpperCase() + queue.slice(1);
  }

  transportQuery = `conversations?queue=${queue}&` + params.toString();

  try {
    const initialresponse = await fetch((process.env.REACT_APP_BASE_URL as string) + "/" + transportQuery, {
      method: "POST",
      headers: {
        "Authorization": idToken
      },
      body: JSON.stringify(body)
    });
    if (!initialresponse.ok && initialresponse.status === 401) {
      const userInfo = getUser();
      if (!userInfo) {
        return [];
      }
      await handleLogout(userInfo);
      return null;
    }
    // const response = await Axios.get(queryString);
    const response = await (initialresponse.json());
    const conversations = response.Conversations;

    return conversations;

  } catch (err) {
    console.error(`Error retrieving items from tables: ${err}`);
    throw err;
  }
};

// interface GetConversationsParams {
//   status?:string,
//   queue?:string,
//   conversationId?:string,
//   subject?:string,
//   destination?:string,
//   sender?:string,
//   assignedTo?:string,
//   createdAtFrom?:string,
//   createdAtTo?:string,
//   lastUpdatedFrom?:string,
//   lastUpdatedTo?:string,
// }

export const pollAssignedItems = async (): Promise<any> => {

  await isTokenExpired();

  const user: User | null = getUser();

  try {
    if (user) {

      const session = sessionStorage.getItem("Session");
      const idToken = JSON.parse(session ?? "").idToken.jwtToken;
      const query = `${process.env.REACT_APP_BASE_URL}/conversations/emails/assign?email=${user.email}`;
      const response = await fetch(query, {
        headers: {
          "Authorization": idToken
        }
      });

      if (!response.ok && response.status === 401) {
        const userInfo = JSON.parse(sessionStorage.getItem("User") ?? "");

        await handleLogout(userInfo);
        return null;
      }

      if (response.status !== 200) {
        return false;
      }

      const res = await response.json();

      if (res === undefined && !res.Conversations) {
        return false;
      }

      return res.Conversations[0];
    }
  } catch (err) {
    console.error(`Error retrieving items from tables: ${err}`);
    throw err;
  }
};

export const fetchEmail = async (docId: string, emailAddress: string): Promise<any | void> => {
  await isTokenExpired();
  try {
    const session = sessionStorage.getItem("Session");
    const idToken = JSON.parse(session ?? "").idToken.jwtToken;
    const user: User | null = getUser();

    const body = {
      emailAddress,
      userEmailAddress: user?.email
    };

    const response = await fetch(process.env.REACT_APP_BASE_URL + "/conversations/emails?conversationID=" + docId, {
      method: "POST",
      headers: {
        "Authorization": idToken
      },
      body: JSON.stringify(body)
    });

    if (!response.ok && response.status === 401) {
      const userInfo = JSON.parse(sessionStorage.getItem("User") ?? "");

      await handleLogout(userInfo);
      return null;
    }

    if (!response.ok) {
      return;
    }
    const json = await response.json();
    return { emails: json };

  } catch (e) {

    return;
  }
};

export const extendWorkItemVisibility = async (workItems: any) => {

  await isTokenExpired();

  const user: User | null = getUser();

  const session = sessionStorage.getItem("Session");
  const idToken = JSON.parse(session ?? "").idToken.jwtToken;
  try {
    const bodyParams = {
      queue: user?.queue,
      emailRecieptHandle: workItems.conversationRecieptHandle
    };

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/visibility`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": idToken
      },
      body: JSON.stringify(bodyParams)
    });

    if (!response.ok && response.status === 401) {
      const userInfo = JSON.parse(sessionStorage.getItem("User") ?? "");

      await handleLogout(userInfo);
      return null;
    }

    //increase the expireyTime to 25 minutes in the future
    const currentTimestamp = Date.now(); // Current timestamp in milliseconds
    const twentyFiveMinutesInMilliseconds = 25 * 60000; // 25 minutes in milliseconds
    const futureTimestamp = currentTimestamp + twentyFiveMinutesInMilliseconds;

    const sessionWork = {
      ...workItems,
      expireTime: futureTimestamp
    };

    sessionStorage.setItem("workItems", JSON.stringify(sessionWork));

  } catch (error) {
    console.log(error);
  }
};

export const confirmReceived = async (emailAddress: string, conversationId: string, received: boolean) => {

  await isTokenExpired();

  try {

    const session = sessionStorage.getItem("Session");
    const idToken = JSON.parse(session ?? "").idToken.jwtToken;
    const bodyParams = {
      emailAddress,
      conversationId,
      received
    };

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/emails/assign/confirmReceived`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": idToken
      },
      body: JSON.stringify(bodyParams)
    });

    if (!response.ok && response.status === 401) {
      const userInfo = JSON.parse(sessionStorage.getItem("User") ?? "");

      await handleLogout(userInfo);
      return null;
    }

  } catch (error) {
    console.log(error);
  }
};

export const holdItem = async (conversationId: string, emailAddress: string, emailRecieptHandle: string) => {

  await isTokenExpired();

  const user: User | null = getUser();

  try {

    const bodyParams = {
      conversationId,
      queue: user?.queue,
      emailAddress,
      emailRecieptHandle
    };

    const session = sessionStorage.getItem("Session");
    const idToken = JSON.parse(session ?? "").idToken.jwtToken;
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/emails/hold`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": idToken
      },
      body: JSON.stringify(bodyParams)
    });

    if (!response.ok && response.status === 401) {
      const userInfo = JSON.parse(sessionStorage.getItem("User") ?? "");

      await handleLogout(userInfo);
      return null;
    }

  } catch (error) {
    console.log(error);
  }
};

export const updateEmailStatus = async (
  conversationId: string,
  emailAddress: string,
  newStatus: string,
  conversationReceiptHandle: string,
  holdExpire: string,
  assignDateStart: string
): Promise<void | null> => {

  await isTokenExpired();

  const user = getUser();

  try {

    const bodyParams = {
      conversationId,
      emailAddress,
      newStatus,
      queue: user?.queue,
      conversationReceiptHandle,
      holdExpire,
      assignDateStart,
      advisorEmail: user?.email
    };

    const session = sessionStorage.getItem("Session");
    const idToken = JSON.parse(session ?? "").idToken.jwtToken;

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/emails/status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": idToken
      },
      body: JSON.stringify(bodyParams)
    });

    if (!response.ok && response.status === 401) {
      const userInfo = JSON.parse(sessionStorage.getItem("User") ?? "");

      await handleLogout(userInfo);
      return null;
    }

    if (!response.ok) {
      throw new Error("Error update email status");
    }

  } catch (error) {
    console.log(error);
  }
};

export const sendEmail = async (
  customerEmail: string,
  conversationID: string,
  emailBody: string,
  subject: string,
  messageID: string,
  emailReceiptHandle: string,
  attachments: FileType[]
) => {
  await isTokenExpired();
  const user = getUser();

  try {
    const user = getUser();

    if (!user?.queue || !user?.email) return;

    const emailData = {
      customerEmail,
      conversationID,
      emailBody,
      subject,
      messageID,
      emailReceiptHandle,
      attachments,
      queue: user?.queue,
      advisorEmail: user?.email
    };

    const session = sessionStorage.getItem("Session");
    const idToken = JSON.parse(session ?? "").idToken.jwtToken;
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/emails/sendEmail`, {
      method: "POST",
      headers: {
        "Authorization": idToken,
      },
      body: JSON.stringify(emailData)
    });

    if (!response.ok && response.status === 401) {
      const userInfo = JSON.parse(sessionStorage.getItem("User") ?? "");

      await handleLogout(userInfo);
      return null;
    }

  } catch (error) {
    console.log(error);
  }
};

export const checkAvailaiblity = async (conversationID: string, emailAddress: string, checkType: string) => {

  try {

    const user: User | null = getUser();

    const params = {
      conversationID,
      emailAddress,
      checkType,
      advisorEmail: user?.email
    };

    const session = sessionStorage.getItem("Session");
    const idToken = JSON.parse(session ?? "").idToken.jwtToken;

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/check_availability`, {
      method: "POST",
      headers: {
        "Authorization": idToken,
      },
      body: JSON.stringify(params)
    });

    if (!response.ok && response.status === 401) {
      const userInfo = JSON.parse(sessionStorage.getItem("User") ??"");

      await handleLogout(userInfo);
      return null;
    }

    const body = await response.json();

    return body.available;

  } catch (error) {
    console.log(error);
  }
};

export const manuallyAssignItem = async (conversationId: string, emailAddress: string, assignType: string) => {

  try {

    const user = getUser();
    const work = sessionStorage.getItem("workItems");
    const workItem = work && JSON.parse(work);

    const params = {
      conversationId,
      emailAddress,
      newAssignee: user?.email,
      assignType,
      queue: user?.queue,
      holdExpire: workItem?.holdExpireDate,
      assignedRecieptHandle: workItem?.conversationRecieptHandle
    };

    const session = sessionStorage.getItem("Session");
    const idToken = JSON.parse(session ?? "").idToken.jwtToken;

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/emails/assign`, {
      method: "POST",
      headers: {
        "Authorization": idToken,
      },
      body: JSON.stringify(params)
    });

    if (!response.ok && response.status === 401) {
      const userInfo = JSON.parse(sessionStorage.getItem("User") ??"");

      await handleLogout(userInfo);
      return null;
    }

  } catch (error) {
    console.log(error);
  }
};
