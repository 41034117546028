import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LoginPage.module.css";
import { Container, Modal } from "../components/General";
import CircularProgress from "@mui/material/CircularProgress";
import { CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken } from "amazon-cognito-identity-js";
import { useAuthl } from "../contexts/authContext";
import JSEncrypt from "jsencrypt";
import { fetchUserDetails } from "../aws/dynamoDB/activity";
import { Button, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import FormControl from "@mui/material/FormControl";

// This looks like a security risk, but a public key only enables access to encryption, not decryption.
// Public keys (as per the name) are designed s.t. if they are found and stolen, it's okay as the thief can't do anything useful with it.
const publicKey = "-----BEGIN PUBLIC KEY-----\n" +
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgc+QXKYEzHeVQNlCN07EvCCHWp+HWPVK1RVpctx1k/0mC4LRoBEJw+1NKWK0pXCL5njQPpY/xZ6G5g8frYlhPIrfTLXLbqJMQ71MJ8S45twOPHBATrib1sOEZE5maJa+2AnotlYkm7ZYOWTCzsXY07LRLjcXgaQitNDECVmeOKfH4/f2cfLm0UsSwEMywBmitSXEL6rKqVlaFAKJenkLA4z39D1dJrF3Bq1C8HHbm4dn8GCqYWWYi0afemZ2H4D1/rA1X3XKj7THJFncUoFgoU7GzfeZ7pwxw2+pfEVpkdd/d8SeDSds7fytJWcK5Y1kJqREJXkFwxHs6TwXxav0xwIDAQAB\n" +
  "-----END PUBLIC KEY-----";

const encrypt = (text: string) => {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  return encryptor.encrypt(text);
};

export const checkValidPassword = (p: string) => {
  const errors: string[] = [];
  if (p.length < 6) {
    errors.push("Password should be at least 6 characters. ");
  }
  if (!/[a-z]/.test(p)) {
    errors.push("Password should contain at least one lowercase character. ");
  }
  if (!/[A-Z]/.test(p)) {
    errors.push("Password should contain at least one uppercase character. ");
  }
  if (!/[0-9]/.test(p)) {
    errors.push("Password should contain at least one number. ");
  }
  if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(p)) {
    errors.push("Password should contain at least one special character. ");
  }
  return errors;
};

export const isAllNumbers = (input: string) => {
  return /^\d+$/.test(input);
};

function LoginScreen() {

  const navigate = useNavigate();
  const { state, dispatch, setFilterSwitch, setAssignedWorkItems } = useAuthl();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [createPasswordShowPassword, setCreatePasswordShowPassword] = useState([false, false]);
  const [firstTimePasswordState, setFirstTimePasswordState] = useState<firstTimePasswordStateType>({
    openModal: false,
    newPassword: "",
    confirmNewPassword: "",
    validNewPassword: true,
    passwordErrorMsg: "",
    error: false,
    newMessage: ""
  });
  const [forgotPasswordState, setForgotPasswordState] = useState<forgotPasswordStateType>({
    openModal: false,
    validEmail: true,
    emailInput: "",
    step: 1,
    validCode: true,
    codeInput: "",
    validNewPassword: true,
    newPassword: "",
    confirmNewPassword: "",
    passwordErrorMsg: "",
    error: false
  });

  interface firstTimePasswordStateType {
    openModal: boolean,
    newPassword: string,
    confirmNewPassword: string,
    validNewPassword: boolean
    passwordErrorMsg: string,
    error: boolean,
    newMessage?: string
  }

  interface forgotPasswordStateType extends firstTimePasswordStateType {
    validEmail: boolean,
    emailInput: string,
    step: number,
    validCode: boolean,
    codeInput: string
  }

  interface IReset1 {
    email: string,
    type: "reset1" | "reset2"
  }

  interface IReset2 extends IReset1 {
    newPassword: string,
    resetCode: string
  }

  useEffect(() => {
    sessionStorage.clear();
  }, []);
  //Validate email and password

  const checkValidEmail = (address: string) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!re.test(address)) {
      return false;
    }
    return true;
  };

  const invalidDetails = () => {
    setErrors(null);
    const errorArray: string[] = [];
    if (!checkValidEmail(String(email).toLowerCase())) {
      errorArray.push("Invalid Email Input");
    }
    errorArray.push(...checkValidPassword(password));

    if (errorArray.length === 0) {
      return false;
    } else {
      setErrors(errorArray);
      setIsLoading(false);
      return true;
    }
  };

  const signInUser = async () => {
    setIsLoading(true);
    // try {

    if (invalidDetails()) return;

    try {
      const responseText = await fetch(process.env.REACT_APP_BASE_URL + "/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email,
          password: encrypt(password)
        })
      })
        .then((res) => res.text());

      if (responseText === "New password is required.") {
        setFirstTimePasswordState(prev => ({ ...prev, openModal: true, emailInput: email }));
        return;
      }
      const json = JSON.parse(responseText ?? "");
      const idToken = new CognitoIdToken({ IdToken: json.idToken as string });
      const accessToken = new CognitoAccessToken({ AccessToken: json.accessToken as string });
      const refreshToken = new CognitoRefreshToken({ RefreshToken: json.refreshToken as string });
      const session = new CognitoUserSession({ IdToken: idToken, AccessToken: accessToken, RefreshToken: refreshToken });
      loginSuccessful(session, null, null);
      return;

    } catch (e) {
      setErrors(["Error logging in, please check your details"]);
      setIsLoading(false);
    }
  };

  //once the user is authenticated, get their email from their token and set them in storage and navigate them to the correct page
  const loginSuccessful = async (session: CognitoUserSession, firstName: string | null | undefined, lastName: string | null | undefined): Promise<void> => {
    const idToken = session.getIdToken().getJwtToken();
    const payload = JSON.parse(atob(idToken.split(".")[1]));
    const userEmail = payload.email;

    const response = await fetchUserDetails(userEmail, idToken);
    const queue = response.queue;

    sessionStorage.setItem("User", JSON.stringify({ email: userEmail, status: "Busy", firstName: response.firstName, lastName: response.lastName, queue, accountType: response.accountType }));
    sessionStorage.setItem("isLoggedIn", JSON.stringify(true));
    sessionStorage.setItem("Session", JSON.stringify(session));

    //used to query the conversations table.
    sessionStorage.setItem("Query", JSON.stringify({ queryString: `conversations?status=Open&status=In Progress&status=Hold&assignedTo=${userEmail}`, queryArray: ["status_open", "status_inprogress", "status_hold", "assignedto"] }));

    setAssignedWorkItems(null);
    setFilterSwitch((value) => !value);
    setIsLoading(false);
    dispatch({ type: "LOGIN" });
    navigate("/?status_open=true&status_inprogress=true&status_hold=true&assignedto=true");
  };

  async function handleOnConfirmFirstTimePw() {
    if (firstTimePasswordState.confirmNewPassword !== firstTimePasswordState.newPassword) {
      setFirstTimePasswordState(prev => ({ ...prev, validNewPassword: false, passwordErrorMsg: "Passwords do not match" }));
      return;
    }
    if (checkValidPassword(firstTimePasswordState.newPassword).length != 0) {
      setFirstTimePasswordState(prev => ({ ...prev, validNewPassword: false, passwordErrorMsg: "New password must be at least 6 characters long, contain a lower case, an upper case and a special character" }));
      return;
    }
    try {
      setIsLoadingModal(true);
      const response = await fetch(process.env.REACT_APP_BASE_URL + "/users/login", {
        method: "POST", headers: {
          "Content-Type": "application/json"
        }, body: JSON.stringify({
          email: email,
          password: encrypt(password),
          newPassword: encrypt(firstTimePasswordState.newPassword)
        })
      });

      if (!response.ok) {
        throw new Error(`There was a problem setting the password. Status: ${response.status}`);
      }

      setFirstTimePasswordState(prev => ({ ...prev, error: false, newMessage: "Your password has been reset successfully." }));
    }
    catch (e) {
      setFirstTimePasswordState(prev => ({ ...prev, error: true, newMessage: "There was a problem resetting your password." }));
    }
    setIsLoading(false);
    setIsLoadingModal(false);
  }

  const handleCloseFirstTimePw = () => {
    setIsLoading(false);
    setIsLoadingModal(false);
    setFirstTimePasswordState(prev => ({
      ...prev,
      error: false,
      openModal: false,
      newPassword: "",
      confirmNewPassword: "",
      validNewPassword: true,
      passwordErrorMsg: "",
      newMessage: ""
    }));
  };

  const checkValidVerificationCode = (state: forgotPasswordStateType) => {
    if (state.codeInput.length == 6 && isAllNumbers(state.codeInput)) {
      return true;
    }
    return false;
  };

  const handleCloseModal = () => {
    setForgotPasswordState(prev => ({
      ...prev,
      openModal: false,
      validEmail: true,
      emailInput: "",
      step: 1,
      validCode: true,
      codeInput: "",
      validNewPassword: true,
      newPassword: "",
      confirmNewPassword: "",
      passwordErrorMsg: "",
      error: false
    }));
    setIsLoadingModal(false);
    setIsLoading(false);
  };

  async function sendVerificationCode(emailAddress: string) {
    const body: IReset1 = { email: emailAddress, type: "reset1" };
    const response = await fetch(process.env.REACT_APP_BASE_URL + "/users/resetPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`There was a HTTP error. Status: ${response.status}`);
    }
  }

  async function resetPassword(verificationCode: string, emailAddress: string, newPassword: string | false) {
    if (!newPassword) {
      throw new Error("Password provided is false.");
    }
    const body: IReset2 = { email: emailAddress, newPassword: newPassword, resetCode: verificationCode, type: "reset2" };
    const response = await fetch(process.env.REACT_APP_BASE_URL + "/users/resetPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`There was a HTTP error. Status: ${response.status}`);
    }
  }

  const handleNext = async () => {
    console.log("next clicked");
    // Validate inputs when a user clicks "next", based on their current step
    let valid = true;
    setIsLoadingModal(true);
    switch (forgotPasswordState.step) {
    case 1:
      if (!checkValidEmail(forgotPasswordState.emailInput)) {
        setForgotPasswordState(prev => ({ ...prev, validEmail: false }));
        valid = false;
        break;
      }
      try {
        await sendVerificationCode(forgotPasswordState.emailInput);
      } catch (error) {
        console.log("Error sending verification code", error);
        setForgotPasswordState(prev => ({ ...prev, error: true, step: 3 }));
        valid = false;
      }
      break;
    case 2:
      if (!checkValidVerificationCode(forgotPasswordState)) {
        setForgotPasswordState(prev => ({ ...prev, validCode: false }));
        valid = false;
        break;
      }
      setForgotPasswordState(prev => ({ ...prev, validCode: true }));
      if (forgotPasswordState.confirmNewPassword !== forgotPasswordState.newPassword) {
        setForgotPasswordState(prev => ({ ...prev, validNewPassword: false, passwordErrorMsg: "Passwords do not match" }));
        valid = false;
        break;
      }
      if (checkValidPassword(forgotPasswordState.newPassword).length != 0) {
        setForgotPasswordState(prev => ({
          ...prev,
          validNewPassword: false,
          passwordErrorMsg: "New password must be at least 6 characters long, contain a lower case, an upper case and a special character"
        }));
        valid = false;
        break;
      }
      try {
        await resetPassword(forgotPasswordState.codeInput, forgotPasswordState.emailInput, encrypt(forgotPasswordState.newPassword));
      } catch (error) {
        console.log("Error resetting password", error);
        setForgotPasswordState(prev => ({ ...prev, error: true, step: 3 }));
        valid = false;
      }
      break;
    }

    // If there were no errors, increment to the next step
    if (valid) {
      setForgotPasswordState(prev => ({ ...prev, step: prev.step + 1, validEmail: true, validCode: true, validNewPassword: true, error: false }));
    }
    setIsLoadingModal(false);
  };

  const handleOnEnterForgotPw = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && forgotPasswordState.openModal && forgotPasswordState.step < 3) {
      handleNext();
    }
    return;
  };

  const handleOnEnterFirstPw = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && firstTimePasswordState.openModal && firstTimePasswordState.newMessage == "") {
      handleOnConfirmFirstTimePw();
    }
    return;
  };

  const modalSubtitles: { [key: number]: string } = {
    1: "Please enter your email address:",
    2: "Create a new password:",
    3: forgotPasswordState.error ? "There was a problem resetting your password." : "Your password has been reset successfully."
  };

  return (
    <div className={styles.mainContainer}>
      <Container>
        <form className={styles.innerContainer} onSubmit={(e) => {
          e.preventDefault();
          signInUser();
        }}>
          <TextField
            id="userEmail"
            type="email"
            value={email}
            className={styles.input}
            variant="outlined"
            fullWidth
            label="Email Address:"
            onChange={(e) => {
              setEmail(e.target.value.toLowerCase());
            }}
          />
          <br></br>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password:</InputLabel>
            <OutlinedInput
              onChange={(e) => setPassword(e.target.value)}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(prev => !prev)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          {errors && errors.length !== 0 && (
            <p data-testid={"loginError"} className={styles.error}>
              {errors}
            </p>
          )}
          <Button
            variant="contained"
            className={styles.button}
            type="submit"
            onClick={signInUser}
            disabled={isLoading}
            style={{ width: "40%", margin: "20px auto 5px auto", fontSize: "16px" }}
          >
            Sign in
          </Button>
          <Button
            onClick={() => setForgotPasswordState(prev => ({ ...prev, openModal: true }))}
            style={{ width: "40%", margin: "0 auto" }}
          >
            Forgot Password?
          </Button>
          <Modal
            title="Forgot Password"
            open={forgotPasswordState.openModal}
            handleClose={handleCloseModal}
            onConfirm={handleNext}
            handleKeyDown={handleOnEnterForgotPw}
            btnText={isLoadingModal ? <CircularProgress size="1.5rem" style={{ color: "white" }} /> : "Next"}
            includeBtn={forgotPasswordState.step != 3}
            cancelBtnText={forgotPasswordState.step == 3 ? "Close" : "Cancel"}
            disableBtns={isLoadingModal}
            subtitle={modalSubtitles[forgotPasswordState.step]}>
            {forgotPasswordState.step == 1 && <TextField
              error={!forgotPasswordState.validEmail}
              variant="outlined"
              label={"Email Address:"}
              helperText={forgotPasswordState.validEmail ? "" : "Invalid email address"}
              fullWidth
              className={styles.searchBox}
              value={forgotPasswordState.emailInput}
              onChange={(e) => setForgotPasswordState(prev => ({ ...prev, emailInput: e.target.value }))}
            />}
            {forgotPasswordState.step == 2 &&
              <>
                <p style={{
                  margin: "0px",
                  fontSize: "12px",
                  fontFamily: "'Roboto','Helvetica','Arial',sans-serif"
                }}>{`(A verification code was sent to ${forgotPasswordState.emailInput} if an account with this email exists)`}</p><br></br>
                <TextField
                  error={!forgotPasswordState.validCode}
                  variant="outlined"
                  label={"6-digit code:"}
                  helperText={forgotPasswordState.validCode ? "" : "Invalid verification code"}
                  className={styles.searchBox}
                  value={forgotPasswordState.codeInput}
                  onChange={(e) => setForgotPasswordState(prev => ({ ...prev, codeInput: e.target.value }))}
                  name="verification-code"
                />
                <br></br><br></br>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-newpassword">New Password:</InputLabel>
                  <OutlinedInput
                    value={forgotPasswordState.newPassword}
                    onChange={(e) => setForgotPasswordState(prev => ({ ...prev, newPassword: e.target.value }))}
                    error={!forgotPasswordState.validNewPassword}
                    id="outlined-adornment-newpassword"
                    type={createPasswordShowPassword[0] ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setCreatePasswordShowPassword(prev => [!prev[0], prev[1]])}
                          edge="end"
                        >
                          {createPasswordShowPassword[0] ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                    name="new-password"
                  />
                </FormControl>
                <br></br><br></br>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-confirmpassword">Confirm New Password:</InputLabel>
                  <OutlinedInput
                    value={forgotPasswordState.confirmNewPassword}
                    onChange={(e) => setForgotPasswordState(prev => ({ ...prev, confirmNewPassword: e.target.value }))}
                    error={!forgotPasswordState.validNewPassword}
                    id="outlined-adornment-confirmpassword"
                    type={createPasswordShowPassword[1] ? "text" : "password"}
                    aria-describedby="formHelperText"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setCreatePasswordShowPassword(prev => [prev[0], !prev[1]])}
                          edge="end"
                        >
                          {createPasswordShowPassword[1] ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm New Password"
                    name="confirm-new-password"
                  />
                  <FormHelperText id="formHelperText" style={{ color: "#d32f2f" }}>
                    {forgotPasswordState.validNewPassword ? "" : forgotPasswordState.passwordErrorMsg}
                  </FormHelperText>
                </FormControl>
              </>}
          </Modal>
          <Modal
            open={firstTimePasswordState.openModal}
            title="Set Password"
            btnText={isLoadingModal ? <CircularProgress size="1.5rem" style={{ color: "white" }} /> : "OK"}
            subtitle={firstTimePasswordState.newMessage == "" ? `Please set a password for ${email}:` : firstTimePasswordState.newMessage}
            onConfirm={handleOnConfirmFirstTimePw}
            handleClose={handleCloseFirstTimePw}
            handleKeyDown={handleOnEnterFirstPw}
            disableBtns={isLoadingModal}
            includeBtn={firstTimePasswordState.newMessage == ""}
          >
            {firstTimePasswordState.newMessage == "" &&
              <>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-newpassword-f">New Password:</InputLabel>
                  <OutlinedInput
                    value={firstTimePasswordState.newPassword}
                    onChange={(e) => setFirstTimePasswordState(prev => ({ ...prev, newPassword: e.target.value }))}
                    error={!firstTimePasswordState.validNewPassword}
                    id="outlined-adornment-newpassword-f"
                    type={createPasswordShowPassword[0] ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setCreatePasswordShowPassword(prev => [!prev[0], prev[1]])}
                          edge="end"
                        >
                          {createPasswordShowPassword[0] ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password-f"
                    name="new-password-f"
                  />
                </FormControl>
                <br></br><br></br>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-confirmpassword-f">Confirm New Password:</InputLabel>
                  <OutlinedInput
                    value={firstTimePasswordState.confirmNewPassword}
                    onChange={(e) => setFirstTimePasswordState(prev => ({ ...prev, confirmNewPassword: e.target.value }))}
                    error={!forgotPasswordState.validNewPassword}
                    id="outlined-adornment-confirmpassword-f"
                    type={createPasswordShowPassword[1] ? "text" : "password"}
                    aria-describedby="formHelperText"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setCreatePasswordShowPassword(prev => [prev[0], !prev[1]])}
                          edge="end"
                        >
                          {createPasswordShowPassword[1] ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm New Password-f"
                    name="confirm-new-password-f"
                  />
                  <FormHelperText id="formHelperText" style={{ color: "#d32f2f" }}>
                    {firstTimePasswordState.validNewPassword ? "" : firstTimePasswordState.passwordErrorMsg}
                  </FormHelperText>
                </FormControl>
              </>
            }
            <br></br>
          </Modal>
        </form>
      </Container>
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src="/assets/Sprint Reply-LOGO RGB.png"
          alt="reply logo"
        />
      </div>
    </div>
  );
}

export default LoginScreen;
