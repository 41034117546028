import React, { useEffect, useState } from "react";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TablePagination,
  Table,
  TableRow,
  TextField
} from "@mui/material";
import { TABLE_TYPE } from "../../../store/actions/types";
import { EmailFields, Order, UserFields } from "./Table.types";
import { emptyRows, handleChangePage, handleChangeRowsPerPage, handleRequestSort, visibleRows } from "../helpers/tableHelpers";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import styles from "../helpers/tableHelpers.module.css";
import style from "./UserTable.module.css";
import { Loader, Modal } from "../index";
import { deleteUser } from "../../../aws/dynamoDB/users";

// need to add type for rows
interface TableProps {
  rows: any[];
  editUser: (row: any) => void;
  createUser: () => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserTable: React.FC<TableProps> = ({ rows, editUser, createUser, setIsLoading }: TableProps) => {

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof EmailFields | keyof UserFields>("name");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showRows, setShowRows] = useState(rows);
  const [searchEmail, setSearchEmail] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  // const handleClickOptions = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // Avoid a layout jump when reaching the last page with empty rows.
  const getEmptyRows = emptyRows(page, showRows.length, rowsPerPage);

  // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setDense(event.target.checked);
  // };
  useEffect(() => {
    setShowRows(rows);
  },[rows]);

  useEffect(() => {

    if (searchEmail === ""){
      setShowRows(rows);
    } else {
      const searchedRows = rows.filter((value) => value.email.includes(searchEmail));
      setShowRows(searchedRows);
    }

  },[searchEmail]);

  const handleOpenDeleteModal = (row: any) => {
    setSelectedRow(row);
    setOpenDeleteModal(true);
    console.log("rann");
  };

  const handleDelete = async () => {

    setIsLoading(true);
    setOpenDeleteModal(false);

    const response = await deleteUser(selectedRow);

    if (response === "Internal Server Error"){
      setError("Internal Server Error");
      setTimeout(() => {
        setError(null);
      },6000);
      setIsLoading(false);
      return;
    }

    if (selectedRow){
      console.log("RAN HERE");
      const newRows = showRows.filter((value) => value.email !== selectedRow.email);
      setShowRows(newRows);
    }

    setIsLoading(false);
  };
  console.log(showRows);
  return (
    <>
      { error !== null &&
        <div style={{
          position: "fixed",
          top: "0",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "orange",
          padding: "10px 20px",
          border: "1px solid black",
          zIndex: 100,
          width: "auto",
          textAlign: "center",
          marginTop: "1em"
        }}>
          <p>{error}</p>
        </div>
      }

      <Modal
        open={openDeleteModal}
        handleClose={() => {setOpenDeleteModal(false);}}
        onConfirm={handleDelete}
        subtitle="Are you sure you want to delete this user? This cannot be reversed."
      />
      <div className={styles.gridContainer}>
        <div
          style={{
            padding: "10px 20px",
            height: "-webkit-fill-available",
            display: "flex",
            justifyContent: "center", // Center align horizontally
          }}
        >
          <div className={styles.gridInnerContainer} style={{ width: "50%", margin: "0 auto" }}>
            <Box sx={{ width: "100%", height: "inherit" }}>
              <Paper
                sx={{
                  width: "100%",
                  height: "inherit",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <EnhancedTableToolbar createUser={createUser} numSelected={selected.length} tableTitle="Users" tableType={TABLE_TYPE.USER} />
                <div className={style.searchBarContainer}>

                  <TextField
                    variant="standard"
                    label={"Email"}
                    fullWidth
                    className={styles.searchBox}
                    value={searchEmail}
                    onChange={(e) => setSearchEmail(e.target.value)}
                    style={{ marginBottom: "16px", width: "90%", paddingRight: "5px" }}
                  />
                  <p className={style.searchBarClear} onClick={()=>{setSearchEmail("");}}>Clear</p>
                </div>
                <TableContainer
                  sx={{
                    width: "-webkit-fill-available",
                    height: "-webkit-fill-available",
                    padding: "0px 16px"
                  }}
                >
                  <Table
                    sx={{ minWidth: 750, fontSize: "inherit" }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={"asc"}
                      orderBy="name"
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={(e, property) =>
                        handleRequestSort(e, property, order, orderBy, setOrder, setOrderBy)}
                      rowCount={showRows.length}
                      tableType={TABLE_TYPE.USER}
                    />
                    <TableBody >
                      {visibleRows(page, rowsPerPage, showRows, order, orderBy).map((row: any, index) => {
                      // const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            tabIndex={-1}
                            key={row.id || `row-${index}`}
                            sx={{ cursor: "default" }}
                          >
                            <TableCell align="center">
                              {row?.firstName + " " + row?.lastName}
                            </TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            <TableCell align="center">{row.accountType}</TableCell>
                            <TableCell align="center">{row.queueAccess}</TableCell>
                            <TableCell align="center">
                              {<>
                                <EditIcon
                                  data-testid={"edit_" + index}
                                  className={styles.editUserButton}
                                  onClick={() => editUser(row)}
                                  style={{ margin: "0 5px" }}
                                >
                                </EditIcon>
                                <DeleteIcon
                                  className={styles.deleteUserButton}
                                  onClick={() => {handleOpenDeleteModal(row);}}
                                  style={{ margin: "0 5px" }}
                                >
                                </DeleteIcon>
                                {/* <MoreHorizIcon
                                  className={styles.moreOptionsButton}
                                  onClick={handleClickOptions}
                                  style={{ margin: "0 5px" }}
                                >
                                </MoreHorizIcon>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={() => setAnchorEl(null)}
                                  elevation={1}
                                >
                                  <MenuItem onClick={() => console.log("reset password clicked")}>Reset Password</MenuItem>
                                </Menu> */}
                              </>}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {getEmptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * getEmptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[15, 30, 50]}
                  component="div"
                  count={showRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => handleChangePage(e, newPage, setPage)}
                  onRowsPerPageChange={(e) => handleChangeRowsPerPage(e, setRowsPerPage, setPage)}
                  sx={{ minHeight: "52px" }}
                />
              </Paper>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTable;
