import React from "react";
import {
  TableHead,
  TableCell,
  Checkbox,
  TableSortLabel,
  Box,
  TableRow,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { headCellsEmails, headCellsUsers } from "../helpers/tableHelpers";
import { EnhancedTableProps, EmailFields, UserFields } from "./Table.types";
import { TABLE_TYPE } from "../../../store/actions/types";

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
  // onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  tableType
}) => {
  const createSortHandler =
    (property: keyof EmailFields | keyof UserFields) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const headerData = tableType == TABLE_TYPE.EMAIL ? headCellsEmails : headCellsUsers;

  return (
    <TableHead>
      <TableRow>
        {headerData.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.align
                ? headCell.align
                : headCell.numeric
                  ? "right"
                  : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          > {
              headCell.sortable?

                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>:
                headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
