import React, { useState } from "react";

export default function IntentsContainer({ selectedEmail }: { selectedEmail: any }) {
  const intents = [selectedEmail?.intent];
  return <div>
    <p style={{ fontWeight: 600, fontSize: 18 }}>Customer Intents</p>
    {intents.map((intent, index) => {
      return <div key={index}>
        <p style={{ fontWeight: "bold", fontSize: 14 }}>{intent}</p>
        <div style={{ width: "100%", backgroundColor: "lightgray", height: "4px", borderRadius: 5 }} />
      </div>;
    })}
    <div style={{ width: "100%", height: "15px" }}></div>
  </div>;
}
