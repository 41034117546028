import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { buildCurrentQuery } from "../../helpers/buildCurrentQuery";
import { getUser } from "../../helpers/getCurrentUser";

interface SideMenuProps {
  toggleModal: () => void;
}

const AntTabs = styled(Tabs)({
  borderRight: "1px solid #e8e8e8",
  backgroundColor: "#fafafa",
  width: "70px",
  display: "flex",
  alignContent: "center",
  "& .MuiTabs-indicator": {
    backgroundColor: "var(--main-color)",
  },
  "& .MuiTab-labelIcon": {
    minWidth: "70px",
    padding: "0px",
    fontSize: "10px",
    letterSpacing: "0px"
  },
});

const SideMenu: React.FC<SideMenuProps> = ({ toggleModal }) => {
  const [value, setValue] = useState<string>("email");
  const navigate = useNavigate();
  const location = useLocation();

  const getTabValueFromPath = (path: string): string => {
    if (path.includes("manage-accounts")) return "manage-accounts";
    if (path.includes("manage-reports")) return "manage-reports";

    return "email";
  };

  const isSysAdmin = getUser()?.accountType === "System Administrator" ? true : false;

  useEffect(() => {
    const newValue = getTabValueFromPath(location.pathname);
    setValue(newValue);

  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === "email") {
      const query = buildCurrentQuery();

      navigate(`/${query}`);
    }
  };

  const returnToMain = () => {
    if (location.pathname.includes("email")) {
      toggleModal();
    }
  };
  return (
    <AntTabs
      style={{ color: "var(--main-color)", overflow: "unset" }}
      value={value}
      onChange={handleChange}
      aria-label="icon tabs example"
      orientation="vertical"
      textColor="inherit"
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    >
      <Tab
        value="email"
        icon={<EmailIcon aria-label="email"></EmailIcon>}
        label="Email"
        // href="/"
        onClick={returnToMain}
      />
      {isSysAdmin && <Tab
        value="manage-accounts"
        icon={<ManageAccountsIcon sx={{ fontSize: 28 }}></ManageAccountsIcon>}
        label="Manage Accounts"
        // href="/"
        onClick={() => navigate("/manage-accounts")}
      />}
      {/* {isSysAdmin && <Tab
        value="manage-reports"
        icon={<AssessmentIcon sx={{ fontSize: 28 }}></AssessmentIcon>}
        label="Manage Reports"
        // href="/"
        onClick={() => navigate("/manage-reports")}
      />} */}
      {/* <Tab
        value="document"
        icon={
          <DescriptionIcon aria-label="document comparison"></DescriptionIcon>
        }
        label="Docs"
        // href="/"
      /> */}
    </AntTabs>
  );
};

export default SideMenu;
